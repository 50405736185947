define("shared/components/hover-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pFCuWEwm",
    "block": "{\"symbols\":[\"dd\",\"&default\"],\"statements\":[[4,\"basic-dropdown\",null,[[\"renderInPlace\",\"verticalPosition\",\"horizontalPosition\",\"matchTriggerWidth\",\"triggerComponent\",\"contentComponent\",\"registerAPI\",\"calculatePosition\",\"onOpen\",\"onClose\",\"onFocus\"],[[24,[\"renderInPlace\"]],[24,[\"verticalPosition\"]],[24,[\"horizontalPosition\"]],[24,[\"matchTriggerWidth\"]],[24,[\"triggerComponent\"]],[24,[\"contentComponent\"]],[28,\"action\",[[23,0,[]],\"registerAPI\"],null],[28,\"action\",[[23,0,[]],\"calculatePosition\"],null],[24,[\"onOpen\"]],[24,[\"onClose\"]],[24,[\"onFocus\"]]]],{\"statements\":[[0,\"  \"],[14,2,[[28,\"assign\",[[23,1,[]],[28,\"hash\",null,[[\"trigger\",\"content\"],[[28,\"component\",[[23,1,[\"trigger\"]]],[[\"onMouseDown\",\"onMouseEnter\",\"onMouseLeave\"],[[28,\"action\",[[23,0,[]],\"prevent\"],null],[28,\"action\",[[23,0,[]],\"open\",false],null],[28,\"action\",[[23,0,[]],\"close\",false],null]]]],[28,\"component\",[[23,1,[\"content\"]]],[[\"onMouseEnter\",\"onMouseLeave\"],[[28,\"action\",[[23,0,[]],\"open\",true],null],[28,\"action\",[[23,0,[]],\"close\",true],null]]]]]]]],null]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/hover-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});