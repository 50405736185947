define("ember-credit-card/templates/components/credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oLbR4Ihn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"showCard\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"card-wrapper\"],[8],[9],[0,\"\\n\\t\"],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"form\",true],[10,\"id\",\"ember-credit-card-form\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"input\",null,[[\"type\",\"name\",\"value\",\"placeholder\"],[\"text\",\"number\",[24,[\"number\"]],\"Credit Card Number\"]]],false],[0,\"\\n\\t\\t\"],[1,[28,\"input\",null,[[\"type\",\"name\",\"value\",\"placeholder\"],[\"text\",\"name\",[24,[\"name\"]],\"Name on Credit Card\"]]],false],[0,\"\\n\\t\\t\"],[1,[28,\"input\",null,[[\"type\",\"name\",\"value\"],[\"text\",\"expiry\",[24,[\"expiry\"]]]]],false],[0,\"\\n\\t\\t\"],[1,[28,\"input\",null,[[\"type\",\"name\",\"value\"],[\"text\",\"cvc\",[24,[\"cvc\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-credit-card/templates/components/credit-card.hbs"
    }
  });

  _exports.default = _default;
});