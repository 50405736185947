define("shared/components/input-or-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bAST1kuo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"editable\"]]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"hasValue\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"obfuscate\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[22,\"classesForDisplay\"]],[8],[0,\"\\n        \"],[1,[22,\"obfuscatedValue\"],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[22,\"classesForDisplay\"]],[8],[0,\"\\n        \"],[1,[22,\"value\"],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"classesForDisplay\"],\" text-muted\"]]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"generic.na\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/input-or-display/template.hbs"
    }
  });

  _exports.default = _default;
});