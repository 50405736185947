define("global-admin/settings/registration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T4U0hsPS",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"settings/settings-header\"],false],[0,\"\\n\\n\"],[1,[28,\"settings/host-registration\",null,[[\"initialValue\"],[[24,[\"model\",\"host\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/settings/registration/template.hbs"
    }
  });

  _exports.default = _default;
});