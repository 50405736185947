define("shared/components/run-scan-modal/component", ["exports", "shared/mixins/modal-base", "shared/components/run-scan-modal/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),
    cisHelpers: Ember.inject.service(),
    securityScanConfig: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    profile: null,
    loading: true,
    classNames: ['generic', 'about', 'medium-modal'],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.updateProfile();
      this.securityScanConfig.loadAsyncConfigMap(Ember.get(this, 'modalOpts.cluster')).then(function () {
        return Ember.set(_this, 'loading', false);
      });
    },
    actions: {
      run: function run() {
        var _this2 = this;

        var cluster = Ember.get(this, 'modalOpts.cluster');
        var onRun = Ember.get(this, 'modalOpts.onRun');
        var intl = Ember.get(this, 'intl');
        var profile = Ember.get(this, 'profileObject');
        var clusterName = Ember.get(this, 'modalOpts.cluster.displayName');
        cluster.doAction('runSecurityScan', {
          failuresOnly: false,
          skip: null,
          profile: profile.profile,
          overrideBenchmarkVersion: profile.benchmark
        }).then(function () {
          _this2.growl.success(intl.t('cis.scan.growl.success', {
            clusterName: clusterName
          }), '');
        });
        this.get('modalService').toggleModal();
        (onRun || function () {})();
      }
    },
    cisScanProfileOptionsChanged: Ember.observer('cisHelpers.cisScanProfileOptions.[]', function () {
      this.updateProfile();
    }),
    profileObject: Ember.computed('profile', function () {
      return this.cisHelpers.cisScanProfiles[Ember.get(this, 'profile')];
    }),
    testsNotRunning: Ember.computed('cisHelpers.benchmarkLookup', 'profileObject', function () {
      var benchmarkLookup = Ember.get(this, 'cisHelpers.benchmarkLookup');
      var profile = Ember.get(this, 'profileObject');

      if (!benchmarkLookup || !profile) {
        return {};
      }

      var mapping = benchmarkLookup[profile.benchmark];

      if (!mapping) {
        return {};
      }

      var skippedChecks = profile.profile !== 'hardened' ? mapping.skippedChecks : [];
      return {
        skippedChecks: skippedChecks,
        notApplicableChecks: mapping.notApplicableChecks,
        userSkippedChecks: mapping.userSkippedChecks.join(', ')
      };
    }),
    profileDocsHtml: Ember.computed(function () {
      return this.intl.t('cis.scan.modal.profileDocs');
    }),
    testDocsHtml: Ember.computed(function () {
      return this.intl.t('cis.scan.modal.testDocs');
    }),
    updateProfile: function updateProfile() {
      if (this.cisHelpers.cisScanProfileOptions.length > 0) {
        var kubernetesVersion = Ember.get(this, 'modalOpts.cluster.rancherKubernetesEngineConfig.kubernetesVersion');
        var defaultOption = this.cisHelpers.getDefaultCisScanProfileOption(kubernetesVersion);
        Ember.set(this, 'profile', defaultOption);
      }
    }
  });

  _exports.default = _default;
});