define("global-admin/components/role-rule-row/component", ["exports", "ui/utils/constants", "global-admin/components/role-rule-row/template"], function (_exports, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var verbs = _constants.default.RULE_VERBS;

  var _default = Ember.Component.extend({
    layout: _template.default,
    rule: null,
    rules: null,
    resource: null,
    nonResourceURL: null,
    apiGroup: null,
    readOnly: null,
    editing: true,
    tagName: 'TR',
    classNames: 'main-row',
    init: function init() {
      this._super.apply(this, arguments);

      var rule = this.rule;
      var rules = this.rules;
      var _rule$resources = rule.resources,
          resources = _rule$resources === void 0 ? [] : _rule$resources,
          _rule$nonResourceURLs = rule.nonResourceURLs,
          nonResourceURLs = _rule$nonResourceURLs === void 0 ? [] : _rule$nonResourceURLs,
          _rule$apiGroups = rule.apiGroups,
          apiGroups = _rule$apiGroups === void 0 ? [] : _rule$apiGroups;
      var currentVerbs = Ember.get(rule, 'verbs');
      Ember.set(this, 'verbs', verbs.map(function (verb) {
        return {
          key: verb,
          value: currentVerbs.indexOf('*') > -1 || currentVerbs.indexOf(verb) > -1
        };
      }));

      if (Ember.isEmpty(rules)) {
        rules = _constants.default.ROLE_RULES.sort();
        Ember.set(this, 'rules', rules.map(function (rule) {
          return {
            label: rule,
            value: rule.toLowerCase()
          };
        }));
      }

      if (resources.get('length') > 0) {
        this.initResourceLikeParams(resources, 'resource');
      }

      if (nonResourceURLs.get('length') > 0) {
        this.initResourceLikeParams(nonResourceURLs, 'nonResourceURL');
      }

      this.initResourceLikeParams(apiGroups, 'apiGroup');
    },
    actions: {
      remove: function remove() {
        this.remove(this.rule);
      }
    },
    verbChanged: Ember.observer('verbs.@each.{key,value}', function () {
      var verbs = Ember.get(this, 'verbs');
      var selectedVerbs = verbs.filter(function (verb) {
        return verb.value;
      }).map(function (verb) {
        return verb.key;
      });
      var rule = Ember.get(this, 'rule');
      Ember.set(rule, 'verbs', selectedVerbs);
    }),
    selectedResourceChanged: Ember.observer('resource', function () {
      var rule = Ember.get(this, 'rule');
      Ember.set(rule, 'resources', [Ember.get(this, 'resource')]);
    }),
    selectedNonResourceUrlChanged: Ember.observer('nonResourceURL', function () {
      var rule = Ember.get(this, 'rule');
      Ember.set(rule, 'nonResourceURLs', [Ember.get(this, 'nonResourceURL')]);
    }),
    apiGroupsChanged: Ember.observer('apiGroup', function () {
      var rule = Ember.get(this, 'rule');
      Ember.set(rule, 'apiGroups', (Ember.get(this, 'apiGroup') || '').split(','));
    }),
    initResourceLikeParams: function initResourceLikeParams(rules, resourceName) {
      Ember.set(this, resourceName, rules.join(','));
    },
    remove: function remove() {
      throw new Error('remove action is required!');
    }
  });

  _exports.default = _default;
});