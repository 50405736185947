define("shared/components/hover-dropdown/component", ["exports", "shared/components/hover-dropdown/template", "shared/utils/calculate-position", "jquery"], function (_exports, _template, _calculatePosition, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    layout: _template.default,
    delay: 200,
    renderInPlace: true,
    publicDropdownApi: null,
    dropdownCloseDelay: 150,
    ddCloseDelayTimer: null,
    openDelay: Ember.computed.oneWay('delay'),
    closeDelay: Ember.computed.oneWay('delay'),
    actions: {
      registerAPI: function registerAPI(dd) {
        this.registerAPI(dd);
      },
      open: function open(forContent, dropdown) {
        var _this = this;

        if (Ember.get(this, 'closeTimer') || this.isTransitioning()) {
          Ember.run.cancel(Ember.get(this, 'closeTimer'));
          Ember.set(this, 'closeTimer', null);
        } else {
          var openFn = function openFn() {
            Ember.set(_this, 'openTimer', null);

            _this.openDropdown.call(_this, forContent, dropdown);
          };

          var openDelay = Ember.get(this, 'openDelay');

          if (openDelay) {
            Ember.set(this, 'openTimer', Ember.run.later(openFn, openDelay));
          } else {
            openFn();
          }
        }
      },
      close: function close(forContent, dropdown) {
        var _this2 = this;

        if (this.openTimer || this.isTransitioning()) {
          Ember.run.cancel(this.openTimer);
          Ember.set(this, 'openTimer', null);
        } else {
          var closeFn = function closeFn() {
            Ember.set(_this2, 'closeTimer', null);

            _this2.closeDropdown.call(_this2, forContent, dropdown);
          };

          var closeDelay = Ember.get(this, 'closeDelay');

          if (closeDelay) {
            Ember.set(this, 'closeTimer', Ember.run.later(closeFn, closeDelay));
          } else {
            closeFn();
          }
        }
      },
      prevent: function prevent() {
        return false;
      },
      calculatePosition: _calculatePosition.default
    },
    openDropdown: function openDropdown(forContent, dropdown) {
      if (forContent) {
        if (this.onBeforeOpen) {
          this.onBeforeOpen(dropdown);
        }
      }

      dropdown.actions.open();

      if (forContent) {
        if (this.onOpen) {
          this.onOpen(dropdown);
        }
      }
    },
    closeDropdown: function closeDropdown(forContent, dropdown) {
      var skipFocus = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      if (forContent) {
        if (this.onBeforeClose) {
          this.onBeforeClose(dropdown);
        }
      } // signature - event, skipfocus


      dropdown.actions.close(null, skipFocus);

      if (forContent) {
        if (this.onClose) {
          this.onClose(dropdown);
        }
      }
    },
    isTransitioning: function isTransitioning() {
      if (this.router._routerMicrolib && this.router._routerMicrolib.activeTransition && this.router._routerMicrolib.activeTransition.isTransition) {
        return true;
      }

      return false;
    },
    registerAPI: function registerAPI(publicDropdown) {
      Ember.set(this, 'publicDropdownApi', publicDropdown);
    },
    focusIn: function focusIn() {
      if (this.ddCloseDelayTimer) {
        clearTimeout(this.ddCloseDelayTimer);
      }
    },
    focusOut: function focusOut() {
      var _this3 = this;

      if (this.publicDropdownApi && this.publicDropdownApi.isOpen) {
        Ember.set(this, 'ddCloseDelayTimer', setTimeout(function () {
          if (_this3.isDestroyed || _this3.isDestroying) {
            return;
          }

          Ember.set(_this3, 'ddCloseDelayTimer', null);

          _this3.closeDropdown(null, _this3.publicDropdownApi);
        }, this.dropdownCloseDelay));
      }
    },
    keyUp: function keyUp(e) {
      var code = e.keyCode;
      var publicDropdownApi = this.publicDropdownApi;
      var tabList = (0, _jquery.default)("#ember-basic-dropdown-content-".concat(publicDropdownApi.uniqueId, " > LI > a:first-child"));
      var currentFocusIndex = tabList.index(e.target);

      switch (code) {
        case 27:
          {
            if (publicDropdownApi && publicDropdownApi.isOpen) {
              this.closeDropdown(null, publicDropdownApi, false);
            }

            break;
          }

        case 38:
          {
            // up
            var nextIndex = currentFocusIndex - 1;

            if (nextIndex >= tabList.length) {
              tabList.eq(tabList.length).focus();
            } else {
              tabList.eq(nextIndex).focus();
            }

            break;
          }

        case 40:
          {
            // down
            if (publicDropdownApi && publicDropdownApi.isOpen) {
              var _nextIndex = currentFocusIndex + 1;

              if (_nextIndex >= tabList.length) {
                tabList.eq(0).focus();
              } else {
                tabList.eq(_nextIndex).focus();
              }
            } else if (publicDropdownApi && !publicDropdownApi.isOpen) {
              this.openDropdown(null, publicDropdownApi);
              Ember.run.later(function () {
                (0, _jquery.default)("#ember-basic-dropdown-content-".concat(publicDropdownApi.uniqueId, " > LI > a:first-child")).first().focus();
              }, 10);
            }

            break;
          }

        default:
      }
    }
  });

  _exports.default = _default;
});