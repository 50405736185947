define("global-admin/multi-cluster-apps/catalog/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Foh4nnXs",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"catalog-index\",null,[[\"application\",\"catalogId\",\"category\",\"categoryAction\",\"launch\",\"launchRoute\",\"model\",\"parentRoute\",\"refresh\",\"showHeader\"],[[24,[\"application\"]],[24,[\"catalogId\"]],[24,[\"category\"]],[28,\"action\",[[23,0,[]],\"categoryAction\"],null],[28,\"action\",[[23,0,[]],\"launch\"],null],[24,[\"launchRoute\"]],[24,[\"model\"]],[24,[\"parentRoute\"]],[28,\"action\",[[23,0,[]],\"refresh\"],null],true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/multi-cluster-apps/catalog/index/template.hbs"
    }
  });

  _exports.default = _default;
});