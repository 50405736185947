define("shared/components/metrics-graph/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kCsd/uZL",
    "block": "{\"symbols\":[\"row\",\"item\"],\"statements\":[[4,\"each\",[[24,[\"rows\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"col span-4\"],[8],[0,\"\\n        \"],[7,\"h2\",true],[10,\"class\",\"text-left pl-20\"],[8],[1,[28,\"t\",[[28,\"concat\",[\"metrics.\",[23,2,[\"graph\",\"title\"]]],null]],null],false],[9],[0,\"\\n        \"],[1,[28,\"graph-area\",null,[[\"series\",\"loading\",\"formatter\"],[[23,2,[\"series\"]],[24,[\"loading\"]],[23,2,[\"graph\",\"unit\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"noGraphs\"]],[28,\"not\",[[24,[\"loading\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"row mt-20\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"text-center text-muted\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[[24,[\"noDataLabel\"]]],[[\"appName\"],[[24,[\"settings\",\"appName\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"row mt-20\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"icon icon-spinner icon-spin icon-3x\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/metrics-graph/template.hbs"
    }
  });

  _exports.default = _default;
});