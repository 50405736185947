define("shared/components/catalog-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nT+no6mb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[7,\"div\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],[24,[\"launchAction\"]],[24,[\"model\",\"id\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showIcon\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"catalog-icon\"],[8],[0,\"\\n    \"],[7,\"img\",true],[11,\"src\",[29,[[24,[\"app\",\"baseAssets\"]],\"assets/images/generic-catalog.svg\"]]],[11,\"data-src\",[24,[\"model\",\"links\",\"icon\"]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"hr\",true],[10,\"class\",\"ml-20 mr-20\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"model\",\"certified\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"certified\"],[8],[0,\"\\n    \"],[7,\"span\",true],[8],[1,[24,[\"model\",\"certified\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"itemwrap mt-30\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"mb-0\"],[8],[0,\"\\n      \"],[1,[24,[\"model\",\"displayName\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/catalog-box/template.hbs"
    }
  });

  _exports.default = _default;
});