define("shared/components/top-errors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m5767rN7",
    "block": "{\"symbols\":[\"error\",\"line\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"banner-icon\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"icon icon-alert\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"banner-message\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"errors\"]]],null,{\"statements\":[[4,\"if\",[[28,\"is-array\",[[23,1,[]]],null]],null,{\"statements\":[[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"          \"],[7,\"p\",true],[8],[1,[23,2,[]],false],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"p\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/top-errors/template.hbs"
    }
  });

  _exports.default = _default;
});