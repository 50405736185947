define("shared/cis-helpers/service", ["exports", "shared/utils/util", "shared/utils/stateful-promise"], function (_exports, _util, _statefulPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Service.extend({
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    securityScanConfig: Ember.inject.service(),
    createProfileKey: function createProfileKey(profile, benchmark) {
      return profile && benchmark ? "".concat(benchmark.toUpperCase(), " ").concat(profile, " ") : '';
    },
    clusterScanConfigToProfile: function clusterScanConfigToProfile(scanConfig) {
      return this.createProfileKey(scanConfig.cisScanConfig.profile, scanConfig.cisScanConfig.overrideBenchmarkVersion);
    },
    profileToClusterScanConfig: function profileToClusterScanConfig(profileRaw) {
      var _profileRaw$toLowerCa = profileRaw.toLowerCase().split(' '),
          _profileRaw$toLowerCa2 = _slicedToArray(_profileRaw$toLowerCa, 2),
          benchmark = _profileRaw$toLowerCa2[0],
          profile = _profileRaw$toLowerCa2[1];

      return {
        cisScanConfig: {
          failuresOnly: false,
          skip: null,
          overrideBenchmarkVersion: benchmark,
          profile: profile
        }
      };
    },

    /**
     * Converts an id that looks like 1.1.9 into 000010000100009. This
     * allows us to appropriately compare the ids as if they are versions
     * instead of just doing a naive string comparison.
     * @param {*} id
     */
    createSortableId: function createSortableId(id) {
      var columnWidth = 5;
      var splitId = id.trim().split('.');
      return splitId.map(function (column) {
        var suffix = column.match(/[a-z]$/i) ? '' : 'a';
        var columnWithSuffix = column + suffix;
        var columnPaddingWidth = Math.max(columnWidth - columnWithSuffix.length, 0);
        return '0'.repeat(columnPaddingWidth) + columnWithSuffix;
      }).join('');
    },
    defaultClusterScanConfig: Ember.computed(function () {
      return this.profileToClusterScanConfig(this.defaultCisScanProfileOption);
    }),
    cisScanConfigProfiles: Ember.computed(function () {
      return this.globalStore.getById('schema', 'cisscanconfig').optionsFor('profile');
    }),
    cisConfigs: Ember.computed(function () {
      return _statefulPromise.default.wrap(this.globalStore.findAll('cisConfig'), []);
    }),
    benchmarkMapping: Ember.computed('cisConfigs.value', function () {
      var configs = Ember.get(this, 'cisConfigs.value');
      return configs.reduce(function (agg, config) {
        return _objectSpread({}, agg, _defineProperty({}, config.name, config.params.benchmarkVersion));
      }, {});
    }),
    benchmarkMappingValues: Ember.computed('benchmarkMapping', function () {
      return Object.values(Ember.get(this, 'benchmarkMapping'));
    }),
    cisScanBenchmarks: Ember.computed.uniq('benchmarkMappingValues'),
    cisScanProfiles: Ember.computed('cisScanConfigProfiles', 'cisScanBenchmarks', function () {
      var _this = this;

      var profiles = Ember.get(this, 'cisScanConfigProfiles');
      var benchmarks = Ember.get(this, 'cisScanBenchmarks');
      var asArray = profiles.flatMap(function (profile) {
        return benchmarks.map(function (benchmark) {
          return _defineProperty({}, _this.createProfileKey(profile, benchmark), {
            benchmark: benchmark,
            profile: profile
          });
        });
      });
      return asArray.length > 0 ? Object.assign.apply({}, asArray) : {};
    }),
    getDefaultCisScanProfileOption: function getDefaultCisScanProfileOption(kubernetesVersion) {
      var mapping = Ember.get(this, 'benchmarkMapping');
      var version = kubernetesVersion.split('.').slice(0, 2).join('.');
      var defaultBenchmark = mapping[version] ? mapping[version] : mapping['default'];
      var defaultProfile = Ember.get(this, 'cisScanConfigProfiles')[0];
      return this.createProfileKey(defaultProfile, defaultBenchmark);
    },
    cisScanProfileOptions: Ember.computed('cisScanProfiles', function () {
      return Object.keys(Ember.get(this, 'cisScanProfiles')).map(function (key) {
        return {
          label: (0, _util.toTitle)(key),
          value: key
        };
      });
    }),
    defaultCisScanProfileOption: Ember.computed('cisScanProfileOptions', function () {
      return Ember.get(this, 'cisScanProfileOptions')[0].value;
    }),
    benchmarkVersions: Ember.computed(function () {
      return _statefulPromise.default.wrap(this.globalStore.findAll('cisBenchmarkVersion'), []);
    }),
    benchmarkLookup: Ember.computed('benchmarkVersions.value', 'securityScanConfig.parsedSecurityScanConfig', 'securityScanConfig.parsedSecurityScanConfig.skip', function () {
      var _this2 = this;

      var getUserSkip = function getUserSkip(benchmark) {
        try {
          var userSkipLookup = Ember.get(_this2, 'securityScanConfig.parsedSecurityScanConfig.skip');
          var userSkip = userSkipLookup[benchmark];
          var skips = Array.isArray(userSkip) && userSkip.every(function (s) {
            return typeof s === 'string';
          }) ? userSkip : [];
          return skips;
        } catch (ex) {
          return [];
        }
      };

      return Ember.get(this, 'benchmarkVersions.value').filter(function (bv) {
        return bv.info.notApplicableChecks && bv.info.skippedChecks;
      }).reduce(function (agg, bv) {
        return _objectSpread({}, agg, _defineProperty({}, bv.name, {
          notApplicableChecks: Object.entries(bv.info.notApplicableChecks).map(function (e) {
            return {
              sortableId: _this2.createSortableId(e[0]),
              id: e[0],
              why: e[1]
            };
          }).sortBy('sortableId'),
          skippedChecks: Object.entries(bv.info.skippedChecks).map(function (e) {
            return {
              sortableId: _this2.createSortableId(e[0]),
              id: e[0],
              why: e[1]
            };
          }).sortBy('sortableId'),
          userSkippedChecks: getUserSkip(bv.name)
        }));
      }, {});
    })
  });

  _exports.default = _default;
});