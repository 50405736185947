define("global-admin/catalog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OgLnI2oy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"header clearfix\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"right-buttons\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"disabled\",[28,\"rbac-prevents\",null,[[\"resource\",\"scope\",\"permission\"],[\"catalog\",\"global\",\"create\"]]]],[12,\"class\",\"btn bg-primary btn-sm\"],[3,\"action\",[[23,0,[]],\"add\"]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"catalogSettings.more.addActionLabel\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"h1\",true],[8],[1,[28,\"t\",[\"catalogSettings.header\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"custom-catalog\",null,[[\"catalogs\",\"mode\"],[[24,[\"model\"]],\"global\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/catalog/template.hbs"
    }
  });

  _exports.default = _default;
});