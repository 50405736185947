define("global-admin/cluster-templates/new-revision/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JNolfPq4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"header clearfix\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"pull-left\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"clusterTemplatesPage.newRevision.header\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"cru-cluster-template\",[],[[\"@clusterTemplate\",\"@clusterTemplateRevision\",\"@updateTemplateId\",\"@clusterTemplateRevisionId\",\"@psps\",\"@mode\",\"@cancel\",\"@done\"],[[24,[\"model\",\"clusterTemplate\"]],[24,[\"model\",\"clusterTemplateRevision\"]],[28,\"action\",[[23,0,[]],\"updateTemplateId\"],null],[24,[\"model\",\"clusterTemplateRevisionId\"]],[24,[\"model\",\"psps\"]],\"edit\",[28,\"action\",[[23,0,[]],\"cancel\"],null],[28,\"action\",[[23,0,[]],\"done\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/cluster-templates/new-revision/template.hbs"
    }
  });

  _exports.default = _default;
});