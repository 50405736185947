define("shared/components/k3s-cluster-info/component", ["exports", "shared/components/k3s-cluster-info/template", "semver", "shared/utils/sort"], function (_exports, _template, _semver, _sort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Component.extend({
    k3s: Ember.inject.service(),
    layout: _template.default,
    editing: false,
    k3sConfig: Ember.computed.alias('cluster.k3sConfig'),
    allVersions: Ember.computed('k3s.allVersions.[]', function () {
      var currentVersion = Ember.get(this, 'k3sConfig.kubernetesVersion');
      var versionsMapped = [];
      var allVersions = this.k3s.allVersions || [];

      if (!allVersions.includes(currentVersion)) {
        allVersions.unshift(currentVersion);
      }

      allVersions = _toConsumableArray((0, _sort.sortVersions)(allVersions).reverse());
      allVersions.forEach(function (v) {
        if (_semver.default.gte(v, currentVersion)) {
          versionsMapped.pushObject({
            value: v,
            label: v
          });
        }
      });
      return versionsMapped;
    })
  });

  _exports.default = _default;
});