define("shared/components/modal-istio-yaml/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xywMajXq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-yaml\"],[8],[0,\"\\n  \"],[7,\"section\",true],[10,\"class\",\"header clearfix\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[1,[28,\"t\",[\"modalYaml.title.view\"],[[\"name\"],[[24,[\"name\"]]]]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[1,[28,\"new-select\",null,[[\"classNames\",\"content\",\"value\"],[\"form-control\",[24,[\"options\"]],[24,[\"selectedType\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[24,[\"yaml\"]],[24,[\"loading\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"input-yaml\",null,[[\"canChangeName\",\"value\",\"loading\",\"autoResize\",\"readOnly\",\"canUpload\",\"showUpload\",\"showDownload\",\"filename\"],[false,[24,[\"yaml\"]],[24,[\"loading\"]],true,true,false,false,false,[24,[\"filename\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"row mt-20\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"text-center text-muted\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"modalYaml.noData\"],[[\"name\"],[[24,[\"selectedType\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[1,[28,\"copy-to-clipboard\",null,[[\"tooltipText\",\"buttonText\",\"clipboardText\",\"class\"],[\"\",\"copyToClipboard.tooltip\",[24,[\"yaml\"]],\"with-clip\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"style\",\"text-align: center\"],[10,\"class\",\"mt-20 text-center\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn bg-transparent\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"generic.cancel\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/modal-istio-yaml/template.hbs"
    }
  });

  _exports.default = _default;
});