define("shared/components/shortcut-key/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sctz+sSi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[10,\"class\",\"m-5\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"mr-10\"],[8],[4,\"if\",[[24,[\"modifier\"]]],null,{\"statements\":[[7,\"code\",true],[8],[1,[28,\"t\",[[28,\"concat\",[\"modalShortcuts.\",[24,[\"modifier\"]]],null]],[[\"key\"],[[24,[\"key\"]]]]],false],[9]],\"parameters\":[]},{\"statements\":[[7,\"code\",true],[8],[1,[22,\"key\"],false],[9]],\"parameters\":[]}],[9],[0,\"\\n  \"],[1,[28,\"t\",[[24,[\"label\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/shortcut-key/template.hbs"
    }
  });

  _exports.default = _default;
});