enifed("ember-template-compiler/lib/plugins/assert-if-helper-without-arguments", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display"], function (_exports, _debug, _calculateLocationDisplay) {
  "use strict";

  _exports.default = assertIfHelperWithoutArguments;

  function assertIfHelperWithoutArguments(env) {
    let {
      moduleName
    } = env.meta;
    return {
      name: 'assert-if-helper-without-arguments',
      visitor: {
        BlockStatement(node) {
          if (isInvalidBlockIf(node)) {
            true && !false && (0, _debug.assert)(blockAssertMessage(node.path.original) + " " + (0, _calculateLocationDisplay.default)(moduleName, node.loc));
          }
        },

        MustacheStatement(node) {
          if (isInvalidInlineIf(node)) {
            true && !false && (0, _debug.assert)(inlineAssertMessage(node.path.original) + " " + (0, _calculateLocationDisplay.default)(moduleName, node.loc));
          }
        },

        SubExpression(node) {
          if (isInvalidInlineIf(node)) {
            true && !false && (0, _debug.assert)(inlineAssertMessage(node.path.original) + " " + (0, _calculateLocationDisplay.default)(moduleName, node.loc));
          }
        }

      }
    };
  }

  function blockAssertMessage(original) {
    return "#" + original + " requires a single argument.";
  }

  function inlineAssertMessage(original) {
    return "The inline form of the '" + original + "' helper expects two or three arguments.";
  }

  function isInvalidInlineIf(node) {
    return node.path.original === 'if' && (!node.params || node.params.length < 2 || node.params.length > 3);
  }

  function isInvalidBlockIf(node) {
    return node.path.original === 'if' && (!node.params || node.params.length !== 1);
  }
});