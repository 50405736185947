define("shared/components/namespace-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lt4LOl1U",
    "block": "{\"symbols\":[],\"statements\":[[7,\"tr\",true],[10,\"class\",\"group-row\"],[8],[0,\"\\n  \"],[7,\"td\",true],[11,\"colspan\",[28,\"sub\",[[24,[\"fullColspan\"]],1],null]],[10,\"class\",\"pl-10\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"displayName\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"namespaceGroup.label\"],[[\"name\"],[[24,[\"model\",\"displayName\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"t\",[[24,[\"noGroup\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"actions\"]]]]],[10,\"class\",\"actions\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"action-menu\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"showTransitioningMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"tr\",true],[10,\"class\",\"group-row group-row-error\"],[8],[0,\"\\n    \"],[7,\"td\",true],[11,\"colspan\",[29,[[22,\"fullColspan\"]]]],[11,\"class\",[29,[\"text-small \",[24,[\"model\",\"stateColor\"]]]]],[8],[0,\"\\n      \"],[1,[28,\"uc-first\",[[24,[\"model\",\"transitioningMessage\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/namespace-group/template.hbs"
    }
  });

  _exports.default = _default;
});