define("shared/components/cluster-ribbon-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SCWVpJKz",
    "block": "{\"symbols\":[\"clusterProvider\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row nav nav-boxes inline-form mt-30 mb-30\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"over-hr\"],[8],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"clusterRibbonNav.title\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"col span-12 col-inline mt-0 center\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"recentClusterProviders\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"link-to-cluster-driver\",[\"clusters.new\",[28,\"query-params\",null,[[\"provider\"],[[23,1,[\"name\"]]]]]],[[\"clusterName\",\"genericIcon\",\"removeRecent\"],[[23,1,[\"name\"]],[28,\"if\",[[23,1,[\"genericIcon\"]],true],null],[28,\"action\",[[23,0,[]],\"removeRecent\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"mb-20 mt-10 span-12 col col-inline nav-box-item driver\",\"clusters.new.index\"]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"icon icon-plus-circle icon-4x mt-20\\n          \"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"driver-name\"],[8],[0,\"\\n        All Providers\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/cluster-ribbon-nav/template.hbs"
    }
  });

  _exports.default = _default;
});