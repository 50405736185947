define("shared/components/code-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x+r3DMxb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"code\",true],[11,\"class\",[29,[[22,\"languageClass\"]]]],[8],[1,[22,\"highlighted\"],true],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/code-block/template.hbs"
    }
  });

  _exports.default = _default;
});