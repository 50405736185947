define("global-admin/components/modal-add-cloud-credential/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7CKiq39u",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"cru-cloud-credential\",null,[[\"cancelAdd\",\"doneSavingCloudCredential\",\"mode\",\"originalModel\",\"parseAndCollectErrors\"],[[28,\"action\",[[23,0,[]],\"cancel\"],null],[28,\"action\",[[23,0,[]],\"close\"],null],[24,[\"mode\"]],[24,[\"modalOpts\",\"cloudCredential\"]],[28,\"action\",[[23,0,[]],\"errorHandler\"],null]]]],false],[0,\"\\n\"],[1,[28,\"top-errors\",null,[[\"errors\"],[[24,[\"errors\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/components/modal-add-cloud-credential/template.hbs"
    }
  });

  _exports.default = _default;
});