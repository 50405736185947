define("shared/components/modal-save-rke-template/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jtcGpaR8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"modalSaveRkeTemplate.title\"],[[\"clusterName\"],[[24,[\"cluster\",\"displayName\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"help-block\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"modalSaveRkeTemplate.subtitle\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"horizontal-form container-fluid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row inline-form\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col span-6\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"for\",\"save-rke-template-cluster-template-name\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"modalSaveRkeTemplate.name\"],null],false],[1,[22,\"field-required\"],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"id\"],[\"text\",[24,[\"clusterTemplateName\"]],\"save-rke-template-cluster-template-name\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"footer-actions\"],[8],[0,\"\\n  \"],[5,\"save-cancel\",[],[[\"@save\",\"@cancel\",\"@saveDisabled\",\"@cancelLabel\"],[[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null],[22,\"saveDisabled\"],\"generic.closeModal\"]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/modal-save-rke-template/template.hbs"
    }
  });

  _exports.default = _default;
});