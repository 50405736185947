define("shared/components/schema/input-enum/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mVaN/zDl",
    "block": "{\"symbols\":[\"choice\"],\"statements\":[[7,\"select\",true],[10,\"name\",\"\"],[10,\"class\",\"form-control mb-10\"],[11,\"onChange\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"value\"]]],null]],[[\"value\"],[\"target.value\"]]]],[8],[0,\"\\n  \"],[7,\"option\",true],[10,\"value\",\"\"],[8],[1,[28,\"t\",[\"schema.inputEnum.option\"],null],false],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"field\",\"options\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\",true],[11,\"value\",[23,1,[]]],[11,\"selected\",[28,\"eq\",[[23,1,[]],[24,[\"value\"]]],null]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[]],\"LoadBalancer\"],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"model.service.displayKind.loadBalancer\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/schema/input-enum/template.hbs"
    }
  });

  _exports.default = _default;
});