define("global-admin/cluster-templates/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OI5vc6oA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header clearfix\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"pull-left\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"clusterTemplatesPage.index.header\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"vertical-middle\"],[8],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"right-buttons\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"disabled\",\"route\"],[\"btn btn-sm bg-primary\",[28,\"rbac-prevents\",null,[[\"resource\",\"scope\",\"permission\"],[\"cluster\",\"global\",\"create\"]]],\"cluster-templates.new\"]],{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"clusterTemplatesPage.index.newTemplate\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"cluster-templates-index-container\"],[8],[0,\"\\n  \"],[5,\"cluster-templates-table\",[],[[\"@clusterTemplates\",\"@clusterTemplateRevisions\"],[[24,[\"model\",\"clusterTemplates\"]],[24,[\"model\",\"clusterTemplateRevisions\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/cluster-templates/index/template.hbs"
    }
  });

  _exports.default = _default;
});