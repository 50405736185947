define("shared/components/check-computed-override/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VAEGXwWI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"applyClusterTemplate\"]],[24,[\"clusterTemplateCreate\"]]],null]],null,{\"statements\":[[4,\"if\",[[24,[\"applyClusterTemplate\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"computedOverrideAllowed\"]]],null,{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"computedState\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"t\",[\"generic.enabled\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[28,\"t\",[\"generic.disabled\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/check-computed-override/template.hbs"
    }
  });

  _exports.default = _default;
});