define("shared/components/schema/input-hostname/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6K0cDhmR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"settings\",\"ingress-ip-domain\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"radio\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[1,[28,\"radio-button\",null,[[\"selection\",\"value\"],[[24,[\"mode\"]],\"automatic\"]]],false],[0,\" \"],[1,[28,\"t\",[\"formIngress.hostMode.automatic\"],[[\"domain\",\"htmlSafe\"],[[24,[\"settings\",\"ingress-ip-domain\"]],true]]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"radio\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[1,[28,\"radio-button\",null,[[\"selection\",\"value\"],[[24,[\"mode\"]],\"manual\"]]],false],[0,\" \\n      \"],[1,[28,\"t\",[\"formIngress.hostMode.manual\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"mode\"]],\"manual\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"schema/input-string\",null,[[\"value\"],[[24,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"schema/input-string\",null,[[\"value\"],[[24,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/schema/input-hostname/template.hbs"
    }
  });

  _exports.default = _default;
});