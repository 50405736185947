define("global-admin/security/authentication/shibboleth/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eYJ8yvkP",
    "block": "{\"symbols\":[],\"statements\":[[5,\"saml-config\",[],[[\"@createLabel\",\"@editLabel\",\"@editing\",\"@model\",\"@providerName\",\"@providerNamePath\",\"@saveLabel\"],[\"authPage.shibboleth.buttonText.pre\",\"authPage.shibboleth.buttonText.edit\",[22,\"editing\"],[22,\"model\"],\"shibboleth\",\"authPage.saml.providerName.shibboleth\",\"authPage.shibboleth.buttonText.post\"]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/security/authentication/shibboleth/template.hbs"
    }
  });

  _exports.default = _default;
});