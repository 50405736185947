define("shared/components/tooltip-expiring/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+AtfTcIm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"tooltip-expire\"],[8],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"mb-5\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"tooltipExpire.label\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"canRotateCerts\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"rotateCertificates\"]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"tooltipExpire.link\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/tooltip-expiring/template.hbs"
    }
  });

  _exports.default = _default;
});