define("shared/components/form-engine-opts/component", ["exports", "ui/utils/constants", "shared/components/form-engine-opts/template"], function (_exports, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    machine: null,
    showEngineUrl: null,
    defaultEngine: Ember.computed.alias("settings.".concat(_constants.default.SETTING.ENGINE_URL)),
    didReceiveAttrs: function didReceiveAttrs() {
      if (!Ember.get(this, 'expandFn')) {
        Ember.set(this, 'expandFn', function (item) {
          item.toggleProperty('expanded');
        });
      }
    },
    actions: {
      setEngine: function setEngine(url) {
        Ember.set(this, 'machine.engineInstallURL', url);
      }
    },
    engineUrlChoices: Ember.computed('intl.locale', "settings.".concat(_constants.default.SETTING.ENGINE_URL), function () {
      var defaultEngine = Ember.get(this, 'defaultEngine');
      var out = [{
        label: Ember.get(this, 'intl').t('formEngineOpts.engineInstallUrl.recommended'),
        value: defaultEngine
      }, {
        label: 'v1.13.x',
        value: 'https://releases.rancher.com/install-docker/1.13.sh'
      }, {
        label: 'v17.03.x',
        value: 'https://releases.rancher.com/install-docker/17.03.sh'
      }, {
        label: 'v17.06.x',
        value: 'https://releases.rancher.com/install-docker/17.06.sh'
      }, {
        label: 'v18.06.x',
        value: 'https://releases.rancher.com/install-docker/18.06.sh'
      }, {
        label: 'v18.09.x',
        value: 'https://releases.rancher.com/install-docker/18.09.sh'
      }, {
        label: 'v19.03.x',
        value: 'https://releases.rancher.com/install-docker/19.03.sh'
      }, {
        label: Ember.get(this, 'intl').t('formEngineOpts.engineInstallUrl.latest'),
        value: 'https://get.docker.com'
      }];
      return out;
    })
  });

  _exports.default = _default;
});