define("global-admin/components/global-dns-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bzmGbI3o",
    "block": "{\"symbols\":[],\"statements\":[[7,\"tr\",true],[10,\"class\",\"main-row\"],[8],[0,\"\\n  \"],[7,\"td\",true],[10,\"class\",\"row-check\"],[10,\"valign\",\"middle\"],[10,\"style\",\"padding-top: 2px;\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"canBulkRemove\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"check-box\",null,[[\"nodeId\"],[[24,[\"model\",\"id\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"state\"]]]]],[10,\"class\",\"state\"],[8],[0,\"\\n    \"],[1,[28,\"badge-state\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"fqdn\"]]]]],[8],[0,\"\\n    \"],[1,[24,[\"model\",\"fqdn\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"projectIds\"]]]]],[8],[0,\"\\n    \"],[1,[24,[\"model\",\"projectIds\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"providerName\"]]]]],[8],[0,\"\\n    \"],[1,[24,[\"model\",\"providerName\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"actions\"]],\" \"]]],[10,\"class\",\"actions\"],[8],[0,\"\\n    \"],[1,[28,\"action-menu\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/components/global-dns-row/template.hbs"
    }
  });

  _exports.default = _default;
});