define("shared/components/api-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9o5z+5tW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[0,\"\\n  \"],[1,[24,[\"fieldDef\",\"name\"]],false],[1,[28,\"if\",[[24,[\"fieldDef\",\"required\"]],\"*\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"specialField\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"schema/input-multiline\",null,[[\"classNames\",\"value\",\"placeholder\",\"rows\"],[\"form-control\",[24,[\"value\"]],[24,[\"placeholder\"]],1]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"fieldType\"]],\"boolean\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"input\",null,[[\"type\",\"checked\",\"classNames\"],[\"checkbox\",[24,[\"value\"]],\"m-10\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"fieldType\"]],\"float\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"classNames\",\"placeholder\",\"min\",\"max\"],[\"number\",[24,[\"value\"]],\"form-control\",[24,[\"placeholder\"]],[24,[\"fieldDef\",\"min\"]],[24,[\"fieldDef\",\"max\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"fieldType\"]],\"int\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"input-integer\",null,[[\"value\",\"classNames\",\"placeholder\",\"min\",\"max\"],[[24,[\"value\"]],\"form-control\",[24,[\"placeholder\"]],[24,[\"fieldDef\",\"min\"]],[24,[\"fieldDef\",\"max\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"classNames\",\"placeholder\"],[\"text\",[24,[\"value\"]],\"form-control\",[24,[\"fieldDef\",\"placeholder\"]]]]],false],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"text-info\"],[8],[0,\"\\n  \"],[1,[24,[\"fieldDef\",\"description\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/api-field/template.hbs"
    }
  });

  _exports.default = _default;
});