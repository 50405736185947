define("shared/components/modal-yaml/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0TIryfqG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-yaml\"],[8],[0,\"\\n  \"],[1,[28,\"input-yaml\",null,[[\"title\",\"filename\",\"canChangeName\",\"value\",\"loading\",\"autoResize\",\"readOnly\",\"canUpload\",\"showUpload\"],[[28,\"if\",[[24,[\"editing\"]],[28,\"t\",[[28,\"if\",[[24,[\"readOnly\"]],\"modalYaml.title.view\",\"modalYaml.title.edit\"],null]],[[\"name\"],[[24,[\"model\",\"resource\",\"displayName\"]]]]],[28,\"t\",[\"modalYaml.title.new\"],[[\"type\"],[[28,\"uc-first\",[[24,[\"model\",\"resource\",\"displayName\"]]],null]]]]],null],[24,[\"filename\"]],false,[24,[\"model\",\"yaml\"]],[24,[\"loading\"]],true,[24,[\"readOnly\"]],[28,\"not\",[[24,[\"readOnly\"]]],null],[28,\"not\",[[24,[\"readOnly\"]]],null]]]],false],[0,\"\\n  \"],[1,[28,\"copy-to-clipboard\",null,[[\"tooltipText\",\"buttonText\",\"clipboardText\",\"class\"],[\"\",\"copyToClipboard.tooltip\",[24,[\"model\",\"yaml\"]],\"with-clip\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"top-errors\",null,[[\"errors\"],[[24,[\"errors\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"readOnly\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"style\",\"text-align: center\"],[10,\"class\",\"mt-20 text-center\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn bg-transparent\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"generic.cancel\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"save-cancel\",null,[[\"save\",\"cancel\",\"editing\"],[[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null],[24,[\"editing\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/modal-yaml/template.hbs"
    }
  });

  _exports.default = _default;
});