define("shared/components/modal-container-stop/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Uaw/Eo2",
    "block": "{\"symbols\":[\"resource\"],\"statements\":[[7,\"h3\",true],[10,\"class\",\"container-header-text\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"modalContainerStop.header\"],null],false],[0,\":\\n  \"],[7,\"div\",true],[10,\"class\",\"display-name\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"resources\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,1,[\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"footer-actions\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn bg-error\"],[3,\"action\",[[23,0,[]],\"stop\"]],[8],[1,[28,\"t\",[\"modalContainerStop.button\"],null],false],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn bg-transparent\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[1,[28,\"t\",[\"generic.cancel\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"protip\"],[8],[1,[28,\"t\",[\"modalContainerStop.protip\"],[[\"key\"],[[24,[\"alternateLabel\"]]]]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/modal-container-stop/template.hbs"
    }
  });

  _exports.default = _default;
});