define("shared/components/storage-class/provisioner-quobyte/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4zcOOnIh",
    "block": "{\"symbols\":[\"fields\",\"field\"],\"statements\":[[4,\"each\",[[24,[\"fieldsGroup\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row mb-20\"],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"col span-4\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[1,[28,\"t\",[[28,\"concat\",[\"cruStorageClass.\",[24,[\"provisioner\"]],\".\",[23,2,[]],\".label\"],null]],null],false],[9],[0,\"\\n\"],[4,\"input-or-display\",null,[[\"editable\",\"value\"],[[24,[\"editing\"]],[28,\"get\",[[24,[\"model\"]],[23,2,[]]],null]]],{\"statements\":[[0,\"        \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"classNames\",\"placeholder\"],[\"text\",[28,\"get\",[[24,[\"model\"]],[23,2,[]]],null],\"form-control\",[28,\"t\",[[28,\"concat\",[\"cruStorageClass.\",[24,[\"provisioner\"]],\".\",[23,2,[]],\".placeholder\"],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/storage-class/provisioner-quobyte/template.hbs"
    }
  });

  _exports.default = _default;
});