define("shared/components/input-answer-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NAY33uL3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[0,\"\\n  \"],[1,[28,\"or\",[[24,[\"question\",\"label\"]],[24,[\"question\",\"variable\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[24,[\"question\",\"required\"]]],null,{\"statements\":[[0,\"    \"],[1,[22,\"field-required\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"question\",\"supported\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[24,[\"question\",\"inputComponent\"]]],[[\"field\",\"value\",\"namespaceId\",\"namespace\"],[[24,[\"question\"]],[24,[\"answer\"]],[24,[\"namespaceId\"]],[24,[\"namespace\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"class\"],[\"text\",[24,[\"question\",\"answer\"]],\"form-control\"]]],false],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"inputAnswers.unknownType\"],null],false],[0,\" \"],[1,[24,[\"question\",\"type\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"help-block\"],[8],[0,\"\\n  \"],[1,[24,[\"question\",\"description\"]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/input-answer-row/template.hbs"
    }
  });

  _exports.default = _default;
});