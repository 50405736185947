define("global-admin/clusters/new/launch/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    access: Ember.inject.service(),
    settings: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),
    clusterTemplateService: Ember.inject.service('clusterTemplates'),
    _cachedClusterDetails: null,
    beforeModel: function beforeModel(transition) {
      var _this$access$me$hasAd = this.access.me.hasAdmin,
          globalAdmin = _this$access$me$hasAd === void 0 ? false : _this$access$me$hasAd;

      if (!globalAdmin) {
        var _this$modelFor = this.modelFor('clusters.new'),
            clusterTemplates = _this$modelFor.clusterTemplates;

        var _this$settings$cluste = this.settings.clusterTemplateEnforcement,
            clusterTemplateEnforcement = _this$settings$cluste === void 0 ? false : _this$settings$cluste; // setting is string value

        if (clusterTemplateEnforcement === 'true') {
          clusterTemplateEnforcement = true;
        } else {
          clusterTemplateEnforcement = false;
        }

        if (clusterTemplateEnforcement) {
          if (clusterTemplates.length === 1 && Ember.isEmpty(transition.to.params.clusterTemplateRevision)) {
            return this.replaceWith(this.routeName, transition.to.params.provider, {
              queryParams: {
                clusterTemplateRevision: clusterTemplates.firstObject.defaultRevisionId
              }
            });
          }
        }
      }

      return;
    },
    model: function model(params) {
      var gs = this.globalStore;

      var _this$modelFor2 = this.modelFor('clusters.new'),
          kontainerDrivers = _this$modelFor2.kontainerDrivers,
          nodeDrivers = _this$modelFor2.nodeDrivers,
          clusterTemplates = _this$modelFor2.clusterTemplates,
          clusterTemplateRevisions = _this$modelFor2.clusterTemplateRevisions;

      var provider = params.provider,
          clusterTemplateRevision = params.clusterTemplateRevision;
      var cluster = gs.createRecord({
        type: 'cluster',
        name: ''
      });
      var ctr = null;
      var ct = null;
      var ctId = null;
      ctr = clusterTemplateRevisions.findBy('id', clusterTemplateRevision);

      if (clusterTemplateRevision && ctr) {
        ctId = Ember.get(ctr, 'clusterTemplateId');
        ct = clusterTemplates.findBy('id', ctId);
        Ember.setProperties(cluster, {
          clusterTemplateRevisionId: clusterTemplateRevision,
          clusterTemplateId: Ember.get(ct, 'id')
        });
        this.clusterTemplateService.cloneAndPopulateClusterConfig(cluster, ctr);
      } else {
        if (cluster.clusterTemplateId && cluster.clusterTemplateRevisionId && !clusterTemplateRevision) {
          // user deselected RKE Template
          Ember.setProperties(cluster, {
            clusterTemplateId: null,
            clusterTemplateRevisionId: null
          });
        }
      }

      if (this._cachedClusterDetails) {
        Ember.set(cluster, 'name', this._cachedClusterDetails.name);
        Ember.set(this, '_cachedClusterDetails', null);
      }

      return Ember.RSVP.hash({
        cluster: cluster,
        kontainerDrivers: kontainerDrivers,
        nodeDrivers: nodeDrivers,
        provider: provider,
        clusterTemplateRevision: ctr,
        roleTemplates: Ember.get(this, 'roleTemplateService').fetchFilteredRoleTemplates(),
        me: Ember.get(this, 'access.principal'),
        cloudCredentials: gs.findAll('cloudcredential'),
        clusterRoleTemplateBinding: gs.findAll('clusterRoleTemplateBinding'),
        nodeTemplates: gs.findAll('nodeTemplate'),
        psps: gs.findAll('podSecurityPolicyTemplate'),
        users: gs.findAll('user'),
        clusterTemplates: clusterTemplates,
        clusterTemplateRevisions: clusterTemplateRevisions
      });
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting && transition.targetName !== 'error') {
        controller.set('clusterTemplateRevision', null);
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.queryParamsOnly) {
          var name = this.controller.model.cluster.name ? this.controller.model.cluster.name : null;
          Ember.set(this, '_cachedClusterDetails', {
            name: name
          });
        } else {
          if (this._cachedClusterDetails) {
            Ember.set(this, '_cachedClusterDetails', null);
          }
        }
      }
    },
    queryParams: {
      clusterTemplateRevision: {
        refreshModel: true
      }
    }
  });

  _exports.default = _default;
});