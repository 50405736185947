define("global-admin/security/accounts/groups/controller", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HEADERS = [{
    translationKey: 'accountsPage.groups.table.groupName',
    name: 'name',
    sort: ['name']
  }, {
    translationKey: 'accountsPage.groups.table.globalRole',
    name: 'globalRoleName',
    sort: ['globalRole.displayName']
  }];

  var _default = Ember.Controller.extend({
    access: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    sortBy: 'username',
    refreshing: false,
    hasRefreshProviderAccess: false,
    errors: null,
    headers: HEADERS,
    actions: {
      refreshAllTokens: function refreshAllTokens() {
        var _this = this;

        Ember.set(this, 'refreshing', true);
        this.globalStore.request({
          url: '/v3/users?action=refreshauthprovideraccess',
          method: 'POST',
          data: {}
        }).then(function () {
          var successTitle = _this.intl.t('action.refreshAuthProviderAccess.allSuccess.title');

          var successMessage = _this.intl.t('action.refreshAuthProviderAccess.allSuccess.message');

          _this.growl.success(successTitle, successMessage);
        }).catch(function (err) {
          Ember.set(_this, 'errors', [err.message]);
        }).finally(function () {
          Ember.set(_this, 'refreshing', false);
        });
      }
    },
    parsedGroups: Ember.computed('model.globalRoleBindings.@each.{state,id,groupPrincipal}', 'model.groupPrincipals.@each.{groupPrincipalId}', function () {
      var _this$model = this.model,
          globalRoleBindings = _this$model.globalRoleBindings,
          groupPrincipals = _this$model.groupPrincipals; // Because we don't create a group when we associate a group with a GRB we have to individually fetch the groups from the auth provider
      // The list that is displayed on this page is a bit of a fudge and when you take an action on a "group" on this page the user will
      // actually take the action on the GRB. So just associate the GRB now and we can take action on this in the component

      var mutatedGroupPricipals = groupPrincipals.map(function (grp) {
        if (grp) {
          var filterdGrbs = globalRoleBindings.filterBy('groupPrincipalId', grp.id).filter(function (grb) {
            return _constants.default.REMOVEDISH_STATES.indexOf(grb.state) === -1;
          });
          var mappedGrbNamesIds = filterdGrbs.map(function (grb) {
            return {
              groupRoleBindingName: grb.globalRole.displayName,
              groupRoleBindingId: grb.globalRoleId,
              globalRoleBinding: grb
            };
          }).sortBy('groupRoleBindingName');

          if (filterdGrbs.length > 0) {
            Ember.setProperties(grp, {
              globalRoleBindings: filterdGrbs,
              mappedGroupRoleBindingNamesIds: mappedGrbNamesIds
            });
            return grp;
          }
        }
      });
      return mutatedGroupPricipals.compact();
    })
  });

  _exports.default = _default;
});