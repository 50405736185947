define("shared/components/banner-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xyXOUA6B",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"showIcon\"]],[24,[\"titleStr\"]]],null]],null,{\"statements\":[[4,\"if\",[[24,[\"showIcon\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"banner-icon\"],[8],[0,\"\\n      \"],[7,\"span\",true],[11,\"class\",[29,[\"icon \",[22,\"icon\"]]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"titleStr\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"banner-title\"],[11,\"style\",[22,\"titleStyle\"]],[8],[0,\"\\n      \"],[1,[22,\"titleStr\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"banner-message\"],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[1,[22,\"message\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showClose\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",\"banner-close\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"icon icon-sm icon-close\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/banner-message/template.hbs"
    }
  });

  _exports.default = _default;
});