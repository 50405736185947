define("shared/components/new-select/component", ["exports", "shared/components/new-select/template", "shared/utils/stateful-promise", "jquery"], function (_exports, _template, _statefulPromise, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    // possible passed-in values with their defaults:
    content: null,
    prompt: null,
    optionValuePath: 'value',
    optionLabelPath: 'label',
    optionGroupPath: 'group',
    optionDisabledPath: 'disabled',
    value: null,
    useContentForDefaultValue: false,
    localizedPrompt: false,
    localizedLabel: false,
    localizedHtmlLabel: false,
    disabled: false,
    attributeBindings: ['disabled', 'asyncContent.loading:disabled'],
    classNameBindings: ['asyncContent.loading:loading', 'asyncContent.loaded:loaded', 'asyncContent.error:error'],
    classNames: ['new-select'],
    // leaking changes to it via a 2-way binding
    _selection: Ember.computed.reads('selection'),
    // shadow the passed-in `selection` to avoid
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('content')) {
        this.set('content', []);
      }

      this.on('change', this, this._change);
    },
    willDestroyElement: function willDestroyElement() {
      this.off('change', this, this._change);
    },
    setDefaultValueObserver: Ember.observer('asyncContent.value', function () {
      var content = Ember.get(this, 'asyncContent.value');

      if (Ember.get(this, 'useContentForDefaultValue') && content && content.length > 0 && !Ember.get(this, 'value')) {
        this.setValue(content.firstObject);
      }
    }),
    asyncContent: Ember.computed('content', function () {
      return _statefulPromise.default.wrap(Ember.get(this, 'content'), []);
    }),
    ungroupedContent: Ember.computed('asyncContent.value', 'optionGroupPath', function () {
      var groupPath = this.get('optionGroupPath');
      var out = [];
      this.get('asyncContent.value').forEach(function (opt) {
        var key = Ember.get(opt, groupPath);

        if (!key) {
          out.push(opt);
        }
      });
      return out;
    }),
    groupedContent: Ember.computed('asyncContent.value', 'optionGroupPath', function () {
      var groupPath = this.get('optionGroupPath');
      var out = [];
      this.get('asyncContent.value').forEach(function (opt) {
        var key = Ember.get(opt, groupPath);

        if (key) {
          var group = out.filterBy('group', key)[0];

          if (!group) {
            group = {
              group: key,
              options: []
            };
            out.push(group);
          }

          group.options.push(opt);
        }
      });
      return out.sortBy(groupPath);
    }),
    action: function action() {
      return this;
    },
    setValue: function setValue(selection) {
      var value = Ember.get(this, 'value');

      if (selection) {
        if (typeof value === 'function') {
          value(Ember.get(selection, this.get('optionValuePath')));
        } else {
          this.set('value', Ember.get(selection, this.get('optionValuePath')));
        }
      } else {
        this.set('value', null);
      }
    },
    _change: function _change() {
      var selectEl = (0, _jquery.default)(this.element).find('select')[0];
      var selectedIndex = selectEl.selectedIndex;

      if (selectedIndex === -1) {
        return;
      }

      var selectedValue = selectEl.options[selectedIndex].value;
      var content = this.get('asyncContent');
      var selection = content.value.filterBy(this.get('optionValuePath'), selectedValue)[0]; // set the local, shadowed selection to avoid leaking
      // changes to `selection` out via 2-way binding

      this.set('_selection', selection);
      var changeCallback = this.get('action');

      if (changeCallback) {
        changeCallback(selection);
      }

      this.setValue(selection);
    }
  });

  _exports.default = _default;
});