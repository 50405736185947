define("shared/components/form-versions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "80HBAaJ2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"mode\"]],\"view\"],null]],null,{\"statements\":[[0,\"  \"],[1,[22,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"new-select\",null,[[\"classNames\",\"content\",\"value\",\"disabled\"],[\"form-control\",[24,[\"versionChoices\"]],[24,[\"value\"]],[24,[\"disabled\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"mode\"]],\"edit\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[10,\"class\",\"help-block\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"formVersions.helpBlock.label\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/form-versions/template.hbs"
    }
  });

  _exports.default = _default;
});