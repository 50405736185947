define("global-admin/security/authentication/localauth/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gF/xGOi8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[8],[0,\"\\n  \"],[7,\"h2\",true],[8],[0,\"\\n    \"],[1,[22,\"headerText\"],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"authPage.localAuth.subtext.enabled.text\"],[[\"appName\"],[[24,[\"settings\",\"appName\"]]]]],false],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"security.accounts.users\"]],{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"authPage.localAuth.subtext.enabled.linkText\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"authPage.localAuth.subtext.enabled.alwaysOn\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/security/authentication/localauth/template.hbs"
    }
  });

  _exports.default = _default;
});