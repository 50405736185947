define("nodes/components/node-template-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lr7SBQHI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"class\",\"row-check\"],[10,\"valign\",\"middle\"],[10,\"style\",\"padding-top: 2px;\"],[8],[0,\"\\n  \"],[1,[28,\"check-box\",null,[[\"nodeId\"],[[24,[\"model\",\"id\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"state\"]]]]],[10,\"class\",\"state\"],[8],[0,\"\\n  \"],[1,[28,\"badge-state\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"name\"]]]]],[10,\"class\",\"clip\"],[8],[0,\"\\n  \"],[1,[24,[\"model\",\"displayName\"]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"showTransitioningMessage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"clip text-small \",[24,[\"model\",\"stateColor\"]]]]],[8],[1,[28,\"uc-first\",[[24,[\"model\",\"transitioningMessage\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"owner\"]]]]],[10,\"class\",\"clip\"],[8],[0,\"\\n  \"],[1,[24,[\"model\",\"creator\",\"displayName\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"provider\"]]]]],[10,\"class\",\"clip\"],[8],[0,\"\\n  \"],[1,[24,[\"model\",\"displayProvider\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"location\"]]]]],[10,\"class\",\"clip\"],[8],[0,\"\\n  \"],[1,[24,[\"model\",\"displayLocation\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"size\"]]]]],[10,\"class\",\"clip\"],[8],[0,\"\\n  \"],[1,[24,[\"model\",\"displaySize\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"actions\"]]]]],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[1,[28,\"action-menu\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nodes/components/node-template-row/template.hbs"
    }
  });

  _exports.default = _default;
});