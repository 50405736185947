define("shared/components/error-sub-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OEiRPU+G",
    "block": "{\"symbols\":[],\"statements\":[[7,\"tr\",true],[10,\"class\",\"sub-row no-top auto-height\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"leftColspan\"]]],null,{\"statements\":[[0,\"    \"],[7,\"td\",true],[11,\"colspan\",[29,[[22,\"leftColspan\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"td\",true],[10,\"class\",\"pb-5\"],[11,\"colspan\",[29,[[22,\"mainColspan\"]]]],[8],[0,\"\\n    \"],[7,\"small\",true],[11,\"class\",[29,[[24,[\"model\",\"stateColor\"]]]]],[8],[1,[28,\"uc-first\",[[24,[\"model\",\"transitioningMessage\"]]],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"rightColspan\"]]],null,{\"statements\":[[0,\"    \"],[7,\"td\",true],[11,\"colspan\",[29,[[22,\"rightColspan\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/error-sub-row/template.hbs"
    }
  });

  _exports.default = _default;
});