define("global-admin/components/form-volume-policy/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rH5rqH8f",
    "block": "{\"symbols\":[\"choice\",\"choice\",\"index\"],\"statements\":[[4,\"accordion-list-item\",null,[[\"title\",\"detail\",\"status\",\"statusClass\",\"expandAll\",\"expand\"],[[28,\"t\",[\"formVolumePodSecurityPolicy.title\"],null],[28,\"t\",[\"formVolumePodSecurityPolicy.detail\"],null],[24,[\"status\"]],[24,[\"statusClass\"]],[24,[\"expandAll\"]],[28,\"action\",[[23,0,[]],[24,[\"expandFn\"]]],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col box span-12\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[1,[28,\"t\",[\"formVolumePodSecurityPolicy.volumes\"],null],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"readOnly\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"model\",\"volumes\",\"length\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"volumes\"]]],null,{\"statements\":[[0,\"              \"],[1,[28,\"if\",[[23,3,[]],\", \"],null],false],[0,\" \"],[1,[23,2,[]],false],[0,\"\\n\"]],\"parameters\":[2,3]},null],[0,\"          \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"div\",true],[8],[1,[28,\"t\",[\"generic.none\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"select\",true],[10,\"class\",\"form-control\"],[10,\"multiple\",\"true\"],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"modifyVolumes\",\"volumes\"],null]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"volumeChoices\"]]],null,{\"statements\":[[0,\"            \"],[7,\"option\",true],[11,\"value\",[23,1,[]]],[11,\"selected\",[28,\"array-includes\",[[24,[\"model\",\"volumes\"]],[23,1,[]]],null]],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/components/form-volume-policy/template.hbs"
    }
  });

  _exports.default = _default;
});