define("shared/components/storage-class/provisioner-generic/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RC5PMYF0",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"form-key-value\",null,[[\"initialMap\",\"addActionLabel\",\"editing\",\"changed\"],[[24,[\"parameters\"]],\"cruStorageClass.parameters.addActionLabel\",[24,[\"editing\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"parameters\"]]],null]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/storage-class/provisioner-generic/template.hbs"
    }
  });

  _exports.default = _default;
});