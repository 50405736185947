define("shared/components/input-suggest/component", ["exports", "shared/components/input-suggest/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    value: null,
    placheolder: null,
    grouped: null,
    // {group1: [val1, val2], group2: [val3, val4]}
    choices: null,
    // or [val1, val2, val3, val4]
    classNames: ['input-group'],
    init: function init() {
      this._super.apply(this, arguments); // event handlers don't get bound context by default...


      this.onOpen = onOpen.bind(this);
    },
    actions: {
      select: function select(value) {
        this.set('value', value);
      }
    }
  });

  _exports.default = _default;

  function onOpen() {
    (0, _jquery.default)('.dropdown-menu').css({
      right: '0',
      maxWidth: '200px',
      maxHeight: '300px',
      overflow: 'hidden',
      overfloyY: 'auto'
    });
  }
});