define("global-admin/clusters/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aUvwEFi8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"router\",\"currentRoute\",\"localName\"]],\"select\"],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"clustersPage.select.provider.label\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"router\",\"currentRoute\",\"localName\"]],\"cluster-template\"],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"clustersPage.select.template.label\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"clustersPage.launch.new\"],null],false],[0,\" - \"],[1,[28,\"driver-name\",[[24,[\"provider\"]]],null],false],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"cluster-launch\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/clusters/new/template.hbs"
    }
  });

  _exports.default = _default;
});