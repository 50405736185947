define("shared/components/tooltip-warning/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sjS119km",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[3,\"action\",[[23,0,[]],\"hideAccessWarning\"]],[8],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"m-0 inline-block\"],[8],[1,[28,\"t\",[\"tooltipWarning.notConfigured\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"dismiss inline-block\"],[8],[7,\"small\",true],[8],[1,[28,\"t\",[\"tooltipWarning.dismiss\"],null],false],[9],[0,\" \"],[7,\"i\",true],[10,\"class\",\"icon icon-close\"],[8],[9],[0,\" \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/tooltip-warning/template.hbs"
    }
  });

  _exports.default = _default;
});