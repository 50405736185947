define("shared/components/cluster-template-revision-upgrade-notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W/iUHxer",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"cluster\",\"isClusterTemplateUpgradeAvailable\"]]],null,{\"statements\":[[4,\"tooltip-element\",null,[[\"type\",\"model\",\"tooltipTemplate\",\"inlineBlock\"],[\"tooltip-basic\",[28,\"t\",[\"clusterTemplateRevisionUpgradeNotification.tooltip\"],[[\"revision\"],[[24,[\"cluster\",\"clusterTemplate\",\"latestRevision\",\"displayName\"]]]]],\"tooltip-static\",true]],{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"icon-stack rke-template-revision-upgrade-notification\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"icon icon-circle icon-stack-1x\"],[8],[9],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"icon icon-circle-o icon-stack-1x\"],[8],[9],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"icon icon-notification icon-stack-1x\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/cluster-template-revision-upgrade-notification/template.hbs"
    }
  });

  _exports.default = _default;
});