enifed("ember-template-compiler/lib/plugins/assert-reserved-named-arguments", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display"], function (_exports, _debug, _calculateLocationDisplay) {
  "use strict";

  _exports.default = assertReservedNamedArguments;

  function assertReservedNamedArguments(env) {
    let {
      moduleName
    } = env.meta;
    return {
      name: 'assert-reserved-named-arguments',
      visitor: {
        // In general, we don't assert on the invocation side to avoid creating migration
        // hazards (e.g. using angle bracket to invoke a classic component that uses
        // `this.someReservedName`. However, we want to avoid leaking special internal
        // things, such as `__ARGS__`, so those would need to be asserted on both sides.
        AttrNode({
          name,
          loc
        }) {
          if (name === '@__ARGS__') {
            true && !false && (0, _debug.assert)(assertMessage(name) + " " + (0, _calculateLocationDisplay.default)(moduleName, loc));
          }
        },

        HashPair({
          key,
          loc
        }) {
          if (key === '__ARGS__') {
            true && !false && (0, _debug.assert)(assertMessage(key) + " " + (0, _calculateLocationDisplay.default)(moduleName, loc));
          }
        },

        PathExpression({
          original,
          loc
        }) {
          if (isReserved(original)) {
            true && !false && (0, _debug.assert)(assertMessage(original) + " " + (0, _calculateLocationDisplay.default)(moduleName, loc));
          }
        }

      }
    };
  }

  const RESERVED = ['@arguments', '@args', '@block', '@else'];

  function isReserved(name) {
    return RESERVED.indexOf(name) !== -1 || Boolean(name.match(/^@[^a-z]/));
  }

  function assertMessage(name) {
    return "'" + name + "' is reserved.";
  }
});