define("shared/components/schema/input-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2N46aE0Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"conceal\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"input\",null,[[\"value\",\"classNames\"],[[24,[\"value\"]],\"form-control conceal\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"classNames\"],[\"password\",[24,[\"value\"]],\"form-control\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[7,\"span\",true],[10,\"class\",\"input-group-btn\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn bg-primary\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"generate\"]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"inputPassword.buttonText\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/schema/input-password/template.hbs"
    }
  });

  _exports.default = _default;
});