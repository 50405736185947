define("shared/components/modal-move-namespace/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SEihkIoa",
    "block": "{\"symbols\":[\"project\",\"item\"],\"statements\":[[7,\"h2\",true],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"moveNamespace.title\"],[[\"count\",\"name\"],[[24,[\"model\",\"length\"]],[24,[\"model\",\"firstObject\",\"displayName\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"gt\",[[24,[\"model\",\"length\"]],1],null]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[10,\"class\",\"list-unstyled\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[1,[23,2,[\"displayName\"]],false],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"h2\",true],[10,\"class\",\"pt-20\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"moveNamespace.to\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"ul\",true],[10,\"class\",\"list-unstyled pl-30\"],[8],[0,\"\\n  \"],[7,\"label\",true],[8],[0,\"\\n    \"],[1,[28,\"radio-button\",null,[[\"selection\",\"value\"],[[24,[\"projectId\"]],null]]],false],[0,\"\\n    \"],[7,\"b\",true],[8],[1,[28,\"t\",[\"generic.none\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"allProjects\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[7,\"label\",true],[8],[0,\"\\n        \"],[1,[28,\"radio-button\",null,[[\"selection\",\"value\"],[[24,[\"projectId\"]],[23,1,[\"id\"]]]]],false],[0,\"\\n        \"],[1,[23,1,[\"displayName\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[1,[28,\"top-errors\",null,[[\"errors\"],[[24,[\"errors\"]]]]],false],[0,\"\\n\"],[1,[28,\"save-cancel\",null,[[\"editing\",\"saving\",\"editLabel\",\"save\",\"cancel\"],[true,[24,[\"saving\"]],\"moveNamespace.actionLabel\",[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/modal-move-namespace/template.hbs"
    }
  });

  _exports.default = _default;
});