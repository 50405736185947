define("shared/components/node-ip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tRcU3Yre",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"textMuted\"]],\"text-muted\"],null],\" clip\"]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"model\",\"externalIpAddress\"]],[28,\"not-eq\",[[24,[\"model\",\"externalIpAddress\"]],[24,[\"model\",\"ipAddress\"]]],null]],null]],null,{\"statements\":[[4,\"copy-to-clipboard\",null,[[\"clipboardText\",\"size\"],[[24,[\"model\",\"externalIpAddress\"]],\"small\"]],{\"statements\":[[0,\"      \"],[1,[28,\"format-ip\",[[24,[\"model\",\"externalIpAddress\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"     / \\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"model\",\"ipAddress\"]]],null,{\"statements\":[[4,\"copy-to-clipboard\",null,[[\"clipboardText\",\"size\"],[[24,[\"model\",\"ipAddress\"]],\"small\"]],{\"statements\":[[0,\"      \"],[1,[28,\"format-ip\",[[24,[\"model\",\"ipAddress\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/node-ip/template.hbs"
    }
  });

  _exports.default = _default;
});