define("shared/components/schema/input-relative-service/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UgBxEjlv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"input-group\"],[10,\"style\",\"width: 100%\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"custom\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"input\",null,[[\"value\",\"classNames\",\"placeholder\"],[[24,[\"value\"]],[24,[\"inputClass\"]],\"[stack-name/]service-name\"]]],false],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"input-group-addon bg-default p-0\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"input-group-btn p-0\"],[8],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"btn bg-transparent text-info pl-10 pr-10\"],[3,\"action\",[[23,0,[]],\"standard\"]],[8],[7,\"i\",true],[10,\"class\",\"icon icon-close\"],[8],[9],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"new-select\",null,[[\"classNames\",\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"optionGroupPath\",\"prompt\",\"localizedPrompt\"],[[24,[\"inputClass\"]],[24,[\"value\"]],[24,[\"list\"]],\"value\",\"name\",\"group\",\"schema.inputService.prompt\",true]]],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/schema/input-relative-service/template.hbs"
    }
  });

  _exports.default = _default;
});