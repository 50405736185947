define("shared/components/check-override-allowed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9E5gT5OO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"applyClusterTemplate\"]]],null,{\"statements\":[[4,\"if\",[[28,\"has-override\",null,[[\"questions\",\"paramName\"],[[24,[\"questions\"]],[24,[\"paramName\"]]]]]],null,{\"statements\":[[0,\"    \"],[14,1,[[28,\"hash\",null,[[\"hasOverride\"],[true]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[24,[\"value\"]],[28,\"eq\",[[24,[\"value\"]],false],null]],null]],null,{\"statements\":[[0,\"        \"],[1,[22,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"get\",[[24,[\"clusterTemplateRevision\"]],[24,[\"paramName\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"and\",[[28,\"eq\",[[24,[\"mode\"]],\"view\"],null],[28,\"or\",[[28,\"get\",[[24,[\"clusterTemplateRevision\"]],[24,[\"paramName\"]]],null],[28,\"eq\",[[28,\"get\",[[24,[\"clusterTemplateRevision\"]],[24,[\"paramName\"]]],null],false],null]],null]],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"get\",[[24,[\"clusterTemplateRevision\"]],[24,[\"paramName\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/check-override-allowed/template.hbs"
    }
  });

  _exports.default = _default;
});