define("shared/components/advanced-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p75N/sqJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"unless\",[[24,[\"advanced\"]],\"hide\"],null]]]],[8],[0,\"\\n  \"],[14,1,[[24,[\"advanced\"]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"text-right text-small\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn bg-transparent p-0\"],[3,\"action\",[[23,0,[]],\"toggle\"]],[8],[1,[28,\"t\",[[28,\"if\",[[24,[\"advanced\"]],\"advancedSection.hideText\",\"advancedSection.showText\"],null]],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/advanced-section/template.hbs"
    }
  });

  _exports.default = _default;
});