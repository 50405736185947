define("nodes/components/driver-other/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k4IwOg7/",
    "block": "{\"symbols\":[\"al\",\"expandFn\",\"value\",\"key\"],\"statements\":[[4,\"accordion-list\",null,[[\"showExpandAll\"],[false]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"over-hr\"],[8],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[1,[22,\"driverOptionsTitle\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"each\",[[28,\"-each-in\",[[24,[\"schema\",\"resourceFields\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"api-field\",null,[[\"resource\",\"resourceType\",\"field\",\"value\",\"schema\",\"typeDocs\"],[[24,[\"config\"]],[24,[\"driverName\"]],[23,4,[]],[28,\"get\",[[24,[\"config\"]],[23,4,[]]],null],[24,[\"schema\"]],[24,[\"typeDocumentations\"]]]]],false],[0,\"\\n\"]],\"parameters\":[3,4]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"over-hr\"],[8],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[1,[22,\"templateOptionsTitle\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[28,\"form-name-description\",null,[[\"model\",\"nameRequired\",\"rowClass\"],[[24,[\"model\"]],true,\"row mb-10\"]]],false],[0,\"\\n\\n  \"],[1,[28,\"form-user-labels\",null,[[\"initialLabels\",\"setLabels\"],[[24,[\"labelResource\",\"labels\"]],[28,\"action\",[[23,0,[]],\"setLabels\"],null]]]],false],[0,\"\\n\\n  \"],[1,[28,\"form-node-taints\",null,[[\"model\",\"expand\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],[23,2,[]]],null]]]],false],[0,\"\\n\\n  \"],[1,[28,\"form-engine-opts\",null,[[\"machine\",\"showEngineUrl\"],[[24,[\"model\"]],[24,[\"showEngineUrl\"]]]]],false],[0,\"\\n\\n  \"],[1,[28,\"top-errors\",null,[[\"errors\"],[[24,[\"errors\"]]]]],false],[0,\"\\n  \"],[1,[28,\"save-cancel\",null,[[\"save\",\"cancel\",\"editing\"],[[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null],[24,[\"editing\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nodes/components/driver-other/template.hbs"
    }
  });

  _exports.default = _default;
});