define("global-admin/components/modal-revert-setting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cxqg1w7f",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"modalRevertSettings.title\"],[[\"setting\"],[[24,[\"setting\",\"id\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"section\",true],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-content row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col span-12\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"modalRevertSettings.current\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[24,[\"setting\",\"value\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col span-12\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"modalRevertSettings.default\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[24,[\"setting\",\"default\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"top-errors\",null,[[\"errors\"],[[24,[\"errors\"]]]]],false],[0,\"\\n\"],[1,[28,\"save-cancel\",null,[[\"save\",\"cancel\",\"createLabel\"],[[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"action\",[[23,0,[]],\"done\"],null],\"generic.save\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/components/modal-revert-setting/template.hbs"
    }
  });

  _exports.default = _default;
});