define("shared/components/form-node-taints/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3IboTYaF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"accordion-list-item\",null,[[\"title\",\"detail\",\"expandAll\",\"expand\",\"expandOnInit\"],[[28,\"t\",[\"formNodeTaints.title\"],null],[28,\"t\",[\"formNodeTaints.detail\"],null],[24,[\"expandAll\"]],[28,\"action\",[[23,0,[]],[24,[\"expandFn\"]]],null],[24,[\"expandOnInit\"]]]],{\"statements\":[[0,\"  \"],[1,[28,\"node-taints\",null,[[\"isNode\",\"nodeTemplateTaints\",\"model\"],[[24,[\"isNode\"]],[24,[\"nodeTemplateTaints\"]],[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/form-node-taints/template.hbs"
    }
  });

  _exports.default = _default;
});