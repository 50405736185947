define("global-admin/cluster-templates/detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7LYGn7kw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"header clearfix\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"pull-left\"],[8],[0,\"\\n    \"],[1,[24,[\"model\",\"clusterTemplate\",\"displayName\"]],false],[0,\": \"],[1,[24,[\"model\",\"clusterTemplateRevision\",\"displayName\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"right-buttons\"],[8],[0,\"\\n    \"],[5,\"action-menu\",[[12,\"class\",\"pull-right\"]],[[\"@size\",\"@model\"],[\"sm\",[24,[\"model\",\"clusterTemplateRevision\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"cru-cluster-template\",[],[[\"@clusterTemplate\",\"@clusterTemplateRevision\",\"@clusterTemplateRevisionId\",\"@psps\",\"@mode\",\"@cancel\",\"@done\"],[[24,[\"model\",\"clusterTemplate\"]],[24,[\"model\",\"clusterTemplateRevision\"]],[24,[\"model\",\"clusterTemplateRevisionId\"]],[24,[\"model\",\"psps\"]],\"view\",[28,\"action\",[[23,0,[]],\"cancel\"],null],[28,\"action\",[[23,0,[]],\"done\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/cluster-templates/detail/template.hbs"
    }
  });

  _exports.default = _default;
});