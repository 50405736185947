define("global-admin/security/authentication/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j7AOavID",
    "block": "{\"symbols\":[\"driver\"],\"statements\":[[7,\"section\",true],[10,\"class\",\"header clearfix\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"pull-left\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"vertical-middle\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"nav.admin.security.authentication\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"access\",\"provider\"]],\"local\"],null]],null,{\"statements\":[[0,\"      \"],[5,\"banner-message\",[],[[\"@color\",\"@icon\",\"@message\"],[\"bg-warning\",\"icon-alert\",[28,\"t\",[\"authPage.localAuth.warning\"],[[\"appName\"],[[24,[\"settings\",\"appName\"]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"mb-20\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row nav nav-boxes checked-active\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"filteredDrivers\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"available\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"alt\",\"classNames\",\"href\",\"route\"],[[23,1,[\"label\"]],\"col span-2 nav-box-item driver\",false,[28,\"unbound\",[[23,1,[\"route\"]]],null]]],{\"statements\":[[4,\"if\",[[23,1,[\"providerType\"]]],null,{\"statements\":[[0,\"            \"],[7,\"span\",true],[10,\"class\",\"badge bg-info driver-badge\"],[8],[0,\"\\n              \"],[1,[23,1,[\"providerType\"]],false],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[7,\"div\",true],[11,\"class\",[29,[\"auth-driver \",[23,1,[\"css\"]]]]],[8],[9],[0,\"\\n          \"],[7,\"p\",true],[8],[0,\"\\n            \"],[1,[23,1,[\"label\"]],false],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/security/authentication/template.hbs"
    }
  });

  _exports.default = _default;
});