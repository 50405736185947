enifed("ember-template-compiler/tests/plugins/assert-reserved-named-arguments-test", ["ember-template-compiler/index", "internal-test-helpers"], function (_index, _internalTestHelpers) {
  "use strict";

  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: assert-reserved-named-arguments', class extends _internalTestHelpers.AbstractTestCase {
    ["@test '@arguments' is reserved"]() {
      expectAssertion(() => {
        (0, _index.compile)("{{@arguments}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@arguments' is reserved. ('baz/foo-bar' @ L1:C2) ");
      expectAssertion(() => {
        (0, _index.compile)("{{#if @arguments}}Yup{{/if}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@arguments' is reserved. ('baz/foo-bar' @ L1:C6) ");
      expectAssertion(() => {
        (0, _index.compile)("{{input type=(if @arguments \"bar\" \"baz\")}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@arguments' is reserved. ('baz/foo-bar' @ L1:C17) ");
    }

    ["@test '@args' is reserved"]() {
      expectAssertion(() => {
        (0, _index.compile)("{{@args}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@args' is reserved. ('baz/foo-bar' @ L1:C2) ");
      expectAssertion(() => {
        (0, _index.compile)("{{#if @args}}Yup{{/if}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@args' is reserved. ('baz/foo-bar' @ L1:C6) ");
      expectAssertion(() => {
        (0, _index.compile)("{{input type=(if @args \"bar\" \"baz\")}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@args' is reserved. ('baz/foo-bar' @ L1:C17) ");
    }

    ["@test '@block' is reserved"]() {
      expectAssertion(() => {
        (0, _index.compile)("{{@block}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@block' is reserved. ('baz/foo-bar' @ L1:C2) ");
      expectAssertion(() => {
        (0, _index.compile)("{{#if @block}}Yup{{/if}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@block' is reserved. ('baz/foo-bar' @ L1:C6) ");
      expectAssertion(() => {
        (0, _index.compile)("{{input type=(if @block \"bar\" \"baz\")}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@block' is reserved. ('baz/foo-bar' @ L1:C17) ");
    }

    ["@test '@else' is reserved"]() {
      expectAssertion(() => {
        (0, _index.compile)("{{@else}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@else' is reserved. ('baz/foo-bar' @ L1:C2) ");
      expectAssertion(() => {
        (0, _index.compile)("{{#if @else}}Yup{{/if}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@else' is reserved. ('baz/foo-bar' @ L1:C6) ");
      expectAssertion(() => {
        (0, _index.compile)("{{input type=(if @else \"bar\" \"baz\")}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@else' is reserved. ('baz/foo-bar' @ L1:C17) ");
    } // anything else that doesn't start with a lower case letter


    ["@test '@Arguments' is reserved"]() {
      expectAssertion(() => {
        (0, _index.compile)("{{@Arguments}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@Arguments' is reserved. ('baz/foo-bar' @ L1:C2) ");
      expectAssertion(() => {
        (0, _index.compile)("{{#if @Arguments}}Yup{{/if}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@Arguments' is reserved. ('baz/foo-bar' @ L1:C6) ");
      expectAssertion(() => {
        (0, _index.compile)("{{input type=(if @Arguments \"bar\" \"baz\")}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@Arguments' is reserved. ('baz/foo-bar' @ L1:C17) ");
    }

    ["@test '@Args' is reserved"]() {
      expectAssertion(() => {
        (0, _index.compile)("{{@Args}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@Args' is reserved. ('baz/foo-bar' @ L1:C2) ");
      expectAssertion(() => {
        (0, _index.compile)("{{#if @Args}}Yup{{/if}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@Args' is reserved. ('baz/foo-bar' @ L1:C6) ");
      expectAssertion(() => {
        (0, _index.compile)("{{input type=(if @Args \"bar\" \"baz\")}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@Args' is reserved. ('baz/foo-bar' @ L1:C17) ");
    }

    ["@test '@FOO' is reserved"]() {
      expectAssertion(() => {
        (0, _index.compile)("{{@FOO}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@FOO' is reserved. ('baz/foo-bar' @ L1:C2) ");
      expectAssertion(() => {
        (0, _index.compile)("{{#if @FOO}}Yup{{/if}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@FOO' is reserved. ('baz/foo-bar' @ L1:C6) ");
      expectAssertion(() => {
        (0, _index.compile)("{{input type=(if @FOO \"bar\" \"baz\")}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@FOO' is reserved. ('baz/foo-bar' @ L1:C17) ");
    }

    ["@test '@Foo' is reserved"]() {
      expectAssertion(() => {
        (0, _index.compile)("{{@Foo}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@Foo' is reserved. ('baz/foo-bar' @ L1:C2) ");
      expectAssertion(() => {
        (0, _index.compile)("{{#if @Foo}}Yup{{/if}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@Foo' is reserved. ('baz/foo-bar' @ L1:C6) ");
      expectAssertion(() => {
        (0, _index.compile)("{{input type=(if @Foo \"bar\" \"baz\")}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@Foo' is reserved. ('baz/foo-bar' @ L1:C17) ");
    }

    ["@test '@.' is reserved"]() {
      expectAssertion(() => {
        (0, _index.compile)("{{@.}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@.' is reserved. ('baz/foo-bar' @ L1:C2) ");
      expectAssertion(() => {
        (0, _index.compile)("{{#if @.}}Yup{{/if}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@.' is reserved. ('baz/foo-bar' @ L1:C6) ");
      expectAssertion(() => {
        (0, _index.compile)("{{input type=(if @. \"bar\" \"baz\")}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@.' is reserved. ('baz/foo-bar' @ L1:C17) ");
    }

    ["@test '@_' is reserved"]() {
      expectAssertion(() => {
        (0, _index.compile)("{{@_}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@_' is reserved. ('baz/foo-bar' @ L1:C2) ");
      expectAssertion(() => {
        (0, _index.compile)("{{#if @_}}Yup{{/if}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@_' is reserved. ('baz/foo-bar' @ L1:C6) ");
      expectAssertion(() => {
        (0, _index.compile)("{{input type=(if @_ \"bar\" \"baz\")}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@_' is reserved. ('baz/foo-bar' @ L1:C17) ");
    }

    ["@test '@-' is reserved"]() {
      expectAssertion(() => {
        (0, _index.compile)("{{@-}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@-' is reserved. ('baz/foo-bar' @ L1:C2) ");
      expectAssertion(() => {
        (0, _index.compile)("{{#if @-}}Yup{{/if}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@-' is reserved. ('baz/foo-bar' @ L1:C6) ");
      expectAssertion(() => {
        (0, _index.compile)("{{input type=(if @- \"bar\" \"baz\")}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@-' is reserved. ('baz/foo-bar' @ L1:C17) ");
    }

    ["@test '@$' is reserved"]() {
      expectAssertion(() => {
        (0, _index.compile)("{{@$}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@$' is reserved. ('baz/foo-bar' @ L1:C2) ");
      expectAssertion(() => {
        (0, _index.compile)("{{#if @$}}Yup{{/if}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@$' is reserved. ('baz/foo-bar' @ L1:C6) ");
      expectAssertion(() => {
        (0, _index.compile)("{{input type=(if @$ \"bar\" \"baz\")}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@$' is reserved. ('baz/foo-bar' @ L1:C17) ");
    }

    ["@test '@__ARGS__' is reserved"]() {
      expectAssertion(() => {
        (0, _index.compile)("<Foo @__ARGS__=\"bar\" />", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@__ARGS__' is reserved. ('baz/foo-bar' @ L1:C5) ");
      expectAssertion(() => {
        (0, _index.compile)("{{foo __ARGS__=\"bar\"}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'__ARGS__' is reserved. ('baz/foo-bar' @ L1:C6) ");
      expectAssertion(() => {
        (0, _index.compile)("{{#let (component \"foo\" __ARGS__=\"bar\") as |c|}}{{c}}{{/let}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'__ARGS__' is reserved. ('baz/foo-bar' @ L1:C24) ");
      expectAssertion(() => {
        (0, _index.compile)("{{@__ARGS__}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@__ARGS__' is reserved. ('baz/foo-bar' @ L1:C2) ");
      expectAssertion(() => {
        (0, _index.compile)("{{#if @__ARGS__}}Yup{{/if}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@__ARGS__' is reserved. ('baz/foo-bar' @ L1:C6) ");
      expectAssertion(() => {
        (0, _index.compile)("{{input type=(if @__ARGS__ \"bar\" \"baz\")}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "'@__ARGS__' is reserved. ('baz/foo-bar' @ L1:C17) ");
    }

    ["@test '@' is de facto reserved (parse error)"](assert) {
      assert.throws(() => {
        (0, _index.compile)('{{@}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
      assert.throws(() => {
        (0, _index.compile)('{{#if @}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
      assert.throws(() => {
        (0, _index.compile)('{{input type=(if @ "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
    }

    ["@test '@0' is de facto reserved (parse error)"](assert) {
      assert.throws(() => {
        (0, _index.compile)('{{@0}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
      assert.throws(() => {
        (0, _index.compile)('{{#if @0}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
      assert.throws(() => {
        (0, _index.compile)('{{input type=(if @0 "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
    }

    ["@test '@1' is de facto reserved (parse error)"](assert) {
      assert.throws(() => {
        (0, _index.compile)('{{@1}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
      assert.throws(() => {
        (0, _index.compile)('{{#if @1}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
      assert.throws(() => {
        (0, _index.compile)('{{input type=(if @1 "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
    }

    ["@test '@2' is de facto reserved (parse error)"](assert) {
      assert.throws(() => {
        (0, _index.compile)('{{@2}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
      assert.throws(() => {
        (0, _index.compile)('{{#if @2}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
      assert.throws(() => {
        (0, _index.compile)('{{input type=(if @2 "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
    }

    ["@test '@@' is de facto reserved (parse error)"](assert) {
      assert.throws(() => {
        (0, _index.compile)('{{@@}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
      assert.throws(() => {
        (0, _index.compile)('{{#if @@}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
      assert.throws(() => {
        (0, _index.compile)('{{input type=(if @@ "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
    }

    ["@test '@=' is de facto reserved (parse error)"](assert) {
      assert.throws(() => {
        (0, _index.compile)('{{@=}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
      assert.throws(() => {
        (0, _index.compile)('{{#if @=}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
      assert.throws(() => {
        (0, _index.compile)('{{input type=(if @= "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
    }

    ["@test '@!' is de facto reserved (parse error)"](assert) {
      assert.throws(() => {
        (0, _index.compile)('{{@!}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
      assert.throws(() => {
        (0, _index.compile)('{{#if @!}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
      assert.throws(() => {
        (0, _index.compile)('{{input type=(if @! "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
    }

  });
});