define("shared/components/form-ingress-rule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rrDyVGj+",
    "block": "{\"symbols\":[\"rule\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"label\",true],[8],[1,[28,\"t\",[\"formIngress.label\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"rules\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"form-ingress-rows\",null,[[\"editing\",\"ingress\",\"namespace\",\"removeRule\",\"rule\",\"rules\"],[[24,[\"editing\"]],[24,[\"ingress\"]],[24,[\"namespace\"]],[28,\"action\",[[23,0,[]],\"removeRule\"],null],[23,1,[]],[24,[\"rules\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"p-20\"],[8],[1,[28,\"t\",[\"formIngress.noRules\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"editing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn bg-link icon-btn pull-right\"],[3,\"action\",[[23,0,[]],\"addRule\"]],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"darken\"],[8],[7,\"i\",true],[10,\"class\",\"icon icon-plus text-small\"],[8],[9],[9],[0,\"\\n      \"],[7,\"span\",true],[8],[1,[28,\"t\",[\"formIngress.addRuleLabel\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/form-ingress-rule/template.hbs"
    }
  });

  _exports.default = _default;
});