define("shared/components/modal-shell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JRCArgB8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"container-shell\",null,[[\"instance\",\"containerName\",\"cols\",\"rows\",\"dismiss\",\"disconncted\"],[[24,[\"originalModel\"]],[24,[\"containerName\"]],80,24,[28,\"action\",[[23,0,[]],\"cancel\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"footer-actions\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn bg-primary\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[1,[28,\"t\",[\"generic.closeModal\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/modal-shell/template.hbs"
    }
  });

  _exports.default = _default;
});