define("shared/components/form-versions/component", ["exports", "shared/components/form-versions/template", "shared/utils/constants", "shared/utils/parse-version", "shared/utils/sort", "semver"], function (_exports, _template, _constants, _parseVersion, _sort, _semver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    cluster: null,
    versionChoices: null,
    versions: null,
    initialVersion: null,
    disabled: false,
    value: null,
    mode: 'new',
    showNotAllowed: false,
    supportedVersionsRange: null,
    editing: Ember.computed.equal('mode', 'edit'),
    isView: Ember.computed.equal('mode', 'view'),
    defaultK8sVersionRange: Ember.computed.alias("settings.".concat(_constants.default.SETTING.VERSION_SYSTEM_K8S_DEFAULT_RANGE)),
    defaultK8sVersion: Ember.computed.alias("settings.".concat(_constants.default.SETTING.VERSION_RKE_K8S_DEFAULT)),
    shouldRecomputInitalValues: Ember.on('init', Ember.observer('cluster.clusterTemplateRevisionId', function () {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        // we should set this everytime. we can potentially override it based on
        // satisfies overrides, so if we change templates while launching
        // a cluster from a satisfies to a non-satisfies override we should set
        // it back to the system setting
        Ember.set(_this, 'supportedVersionsRange', Ember.get(_this, "settings.".concat(_constants.default.SETTING.VERSION_K8S_SUPPORTED_RANGE)));

        _this.initVersions();
      });
    })),
    isRke: Ember.computed('cluster', function () {
      var cluster = this.cluster;

      if (Ember.get(cluster, 'rancherKubernetesEngineConfig')) {
        return true;
      }

      return false;
    }),
    k8sVersionOverride: Ember.computed('clusterTemplateQuestions.@each.variable', function () {
      return (this.clusterTemplateQuestions || []).findBy('variable', 'rancherKubernetesEngineConfig.kubernetesVersion') || {};
    }),
    getLabelSuffixKey: Ember.computed('showNotAllowed', function () {
      var _this$applyClusterTem = this.applyClusterTemplate,
          applyClusterTemplate = _this$applyClusterTem === void 0 ? false : _this$applyClusterTem,
          _this$clusterTemplate = this.clusterTemplateCreate,
          clusterTemplateCreate = _this$clusterTemplate === void 0 ? false : _this$clusterTemplate,
          showNotAllowed = this.showNotAllowed;
      var suffix = showNotAllowed ? 'formVersions.notallowed' : 'formVersions.unsupported'; // if we're not consuming or creating a cluster template we should use the default translation for the label

      if (!applyClusterTemplate && !clusterTemplateCreate) {
        suffix = 'formVersions.unsupported';
      }

      return suffix;
    }),
    initVersions: function initVersions() {
      var _this2 = this;

      var versions = this.versions,
          editing = this.editing,
          initialVersion = this.initialVersion,
          defaultK8sVersion = this.defaultK8sVersion,
          k8sVersionOverride = this.k8sVersionOverride,
          intl = this.intl,
          suffix = this.getLabelSuffixKey;
      var clonedVersions = versions.slice();

      if (Ember.isEmpty(initialVersion)) {
        initialVersion = defaultK8sVersion;
      }

      if (editing && !clonedVersions.includes(initialVersion)) {
        clonedVersions.unshift(initialVersion);
      }

      var initialWithoutX = initialVersion.endsWith('.x') ? initialVersion.replace(/x$/, '0') : initialVersion;
      var maxVersion = this.getMaxVersion(initialVersion, clonedVersions);
      clonedVersions = [].concat(_toConsumableArray((0, _sort.sortVersions)(clonedVersions.filter(function (v) {
        return v.endsWith('.x');
      })).reverse()), _toConsumableArray((0, _sort.sortVersions)(clonedVersions.filter(function (v) {
        return !v.endsWith('.x');
      })).reverse()));
      var mappedVersions = clonedVersions.map(function (v) {
        var label = _this2.parseLabelFromVersion(v);

        var disabled = false;
        var experimental = false;
        var version = (0, _parseVersion.coerceVersion)(v);
        var versionSatisfies = (0, _parseVersion.satisfies)(version, _this2.supportedVersionsRange);

        if (versionSatisfies) {
          if (editing && isCurrentVersionLessThanInitial()) {
            disabled = true;
            label = "".concat(label, " ").concat(intl.t('formVersions.downgrade'));
          }
        } else {
          if ((0, _semver.gt)(version, (0, _parseVersion.coerceVersion)(maxVersion))) {
            if (Ember.isEmpty(k8sVersionOverride.satisfies)) {
              if (!(0, _parseVersion.satisfies)((0, _parseVersion.coerceVersion)(v), '>=1.16 <1.17')) {
                experimental = true;
                label = "".concat(label, " ").concat(intl.t('formVersions.experimental'));
              }
            } else {
              disabled = true;
              label = "".concat(label, " ").concat(intl.t(suffix));
            }
          } else if ((0, _semver.lt)(version, (0, _parseVersion.coerceVersion)(maxVersion))) {
            disabled = true;
            label = "".concat(label, " ").concat(intl.t(suffix));
          }
        }

        function isCurrentVersionLessThanInitial() {
          if ((0, _semver.lt)(version, (0, _parseVersion.coerceVersion)(initialWithoutX)) && (0, _semver.minor)(version) <= (0, _semver.minor)((0, _parseVersion.coerceVersion)(initialWithoutX))) {
            return true;
          }

          return false;
        }

        return {
          disabled: disabled,
          experimental: experimental,
          label: label,
          value: v
        };
      });
      Ember.set(this, 'versionChoices', mappedVersions);
      this.initValue(initialWithoutX, maxVersion);
    },
    initValue: function initValue(initialVersion, maxVersion) {
      var editing = this.editing,
          value = this.value,
          k8sVersionOverride = this.k8sVersionOverride,
          applyClusterTemplate = this.applyClusterTemplate,
          intl = this.intl,
          mappedVersions = this.versionChoices;
      var valueIsPatchVersion = false;
      var initialVersionDoesSatisfy = true;

      if (Ember.isEmpty(value)) {
        value = initialVersion;
      }

      if (value.endsWith('.x')) {
        valueIsPatchVersion = true;
      }

      if (!Ember.isEmpty(k8sVersionOverride.satisfies) && !(0, _parseVersion.satisfies)(initialVersion, k8sVersionOverride.satisfies)) {
        initialVersionDoesSatisfy = false;
      }

      if (editing) {
        if (applyClusterTemplate) {
          if (initialVersionDoesSatisfy) {
            value = initialVersion;
          } else {
            value = maxVersion;
          }
        }
      } else {
        if (applyClusterTemplate && !mappedVersions.findBy('value', value)) {
          value = maxVersion;
        } else {
          if (valueIsPatchVersion) {
            value = intl.t('formVersions.dotx', {
              minor: value.replace(/\.x$/, '')
            });
          }
        }
      }

      Ember.set(this, 'value', value);
    },
    getMaxVersion: function getMaxVersion(initialVersion, versions) {
      var applyClusterTemplate = this.applyClusterTemplate,
          defaultK8sVersionRange = this.defaultK8sVersionRange,
          supportedVersionsRange = this.supportedVersionsRange,
          k8sVersionOverride = this.k8sVersionOverride;
      var maxVersion = (0, _parseVersion.maxSatisfying)(versions, defaultK8sVersionRange);

      if (applyClusterTemplate) {
        if (Ember.isEmpty(k8sVersionOverride.satisfies) && initialVersion.endsWith('.x')) {
          // the template creator lets them override this but the initial version is a dot x so we should choose the biggest version in the .x range
          maxVersion = (0, _parseVersion.maxSatisfying)(versions, initialVersion);
        } else {
          if (k8sVersionOverride.satisfies) {
            supportedVersionsRange = Ember.set(this, 'supportedVersionsRange', k8sVersionOverride.satisfies);
          }

          maxVersion = (0, _parseVersion.maxSatisfying)(versions, supportedVersionsRange);
        }
      }

      return maxVersion;
    },
    parseLabelFromVersion: function parseLabelFromVersion(version) {
      var intl = this.intl;
      var label = version;

      if (!label.startsWith('v')) {
        label = "v".concat(label);
      }

      if (label.endsWith('.x')) {
        label = intl.t('formVersions.dotx', {
          minor: label.replace(/\.x$/, '')
        });
      }

      return label;
    }
  });

  _exports.default = _default;
});