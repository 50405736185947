enifed("ember-template-compiler/tests/plugins/assert-modifiers-not-in-components-test", ["ember-template-compiler/index", "internal-test-helpers"], function (_index, _internalTestHelpers) {
  "use strict";

  if (!true
  /* EMBER_GLIMMER_FORWARD_MODIFIERS_WITH_SPLATTRIBUTES */
  ) {
      (0, _internalTestHelpers.moduleFor)('ember-template-compiler: assert-modifiers-not-in-components', class extends _internalTestHelpers.AbstractTestCase {
        ["@test modifiers are not allowed in components"]() {
          expectAssertion(() => {
            (0, _index.compile)("<TheFoo {{bar \"something\" foo=\"else\"}}/>", {
              moduleName: 'the-foo'
            });
          }, "Passing modifiers to components require the \"ember-glimmer-forward-modifiers-with-splattributes\" canary feature, which has not been stabilized yet. See RFC #435 for details. ('the-foo' @ L1:C0) ");
          expectAssertion(() => {
            (0, _index.compile)("<this.foo {{bar \"something\" foo=\"else\"}}></this.foo>", {
              moduleName: 'the-foo'
            });
          }, "Passing modifiers to components require the \"ember-glimmer-forward-modifiers-with-splattributes\" canary feature, which has not been stabilized yet. See RFC #435 for details. ('the-foo' @ L1:C0) ");
          expectAssertion(() => {
            (0, _index.compile)("<api.foo {{bar \"something\" foo=\"else\"}}></api.foo>", {
              moduleName: 'the-foo'
            });
          }, "Passing modifiers to components require the \"ember-glimmer-forward-modifiers-with-splattributes\" canary feature, which has not been stabilized yet. See RFC #435 for details. ('the-foo' @ L1:C0) ");
          expectAssertion(() => {
            (0, _index.compile)("<@foo {{bar \"something\" foo=\"else\"}}></@foo>", {
              moduleName: 'the-foo'
            });
          }, "Passing modifiers to components require the \"ember-glimmer-forward-modifiers-with-splattributes\" canary feature, which has not been stabilized yet. See RFC #435 for details. ('the-foo' @ L1:C0) ");
          expectAssertion(() => {
            (0, _index.compile)("{{#let this.foo as |local|}}<local {{bar \"something\" foo=\"else\"}}></local>{{/let}}", {
              moduleName: 'the-foo'
            });
          }, "Passing modifiers to components require the \"ember-glimmer-forward-modifiers-with-splattributes\" canary feature, which has not been stabilized yet. See RFC #435 for details. ('the-foo' @ L1:C28) ");
          expectAssertion(() => {
            (0, _index.compile)("<Parent as |local|><local {{bar \"something\" foo=\"else\"}}></local></Parent>", {
              moduleName: 'the-foo'
            });
          }, "Passing modifiers to components require the \"ember-glimmer-forward-modifiers-with-splattributes\" canary feature, which has not been stabilized yet. See RFC #435 for details. ('the-foo' @ L1:C19) ");
        }

      });
    }
});