define("global-admin/components/account-group-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W+tyhNJ+",
    "block": "{\"symbols\":[\"grb\",\"idx\"],\"statements\":[[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"name\"]]]]],[10,\"class\",\"pr-15\"],[8],[0,\"\\n  \"],[5,\"identity-block\",[],[[\"@principalId\",\"@wide\"],[[24,[\"model\",\"id\"]],false]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"globalRoleName\"]]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"comma-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"mappedGroupRoleBindingNamesIds\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[8],[0,\"\\n        \"],[5,\"link-to\",[],[[\"@route\",\"@model\",\"@query\"],[\"security.roles.detail\",[23,1,[\"groupRoleBindingId\"]],[28,\"hash\",null,[[\"type\"],[\"global\"]]]]],{\"statements\":[[0,\"\\n          \"],[1,[23,1,[\"groupRoleBindingName\"]],false]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"actions\"]]]]],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[5,\"action-menu\",[],[[\"@model\"],[[22,\"groupRoleBinding\"]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/components/account-group-row/template.hbs"
    }
  });

  _exports.default = _default;
});