define("shared/components/accordion-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GiOcKIsj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"showExpandAll\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-sm bg-transparent pull-right\"],[3,\"action\",[[23,0,[]],\"expandAll\"]],[8],[1,[28,\"t\",[[28,\"if\",[[24,[\"expandAll\"]],\"generic.collapseAll\",\"generic.expandAll\"],null]],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[14,1,[[23,0,[]],[24,[\"expand\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/accordion-list/template.hbs"
    }
  });

  _exports.default = _default;
});