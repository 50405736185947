define("global-admin/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B8bhWoaD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"header has-tabs clearfix p-0\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"tab-nav\"],[8],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"settings.advanced\"]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"settingsPage.tabs.settings\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"settings.features\"]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"settingsPage.tabs.feature\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/settings/template.hbs"
    }
  });

  _exports.default = _default;
});