enifed("ember-template-compiler/tests/plugins/assert-local-variable-shadowing-helper-invocation-test", ["ember-template-compiler/index", "internal-test-helpers"], function (_index, _internalTestHelpers) {
  "use strict";

  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: assert-local-variable-shadowing-helper-invocation', class extends _internalTestHelpers.AbstractTestCase {
    ["@test block statements shadowing sub-expression invocations"]() {
      expectAssertion(() => {
        (0, _index.compile)("\n          {{#let foo as |foo|}}\n            {{concat (foo)}}\n          {{/let}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` helper because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L3:C21) ");
      expectAssertion(() => {
        (0, _index.compile)("\n          {{#let foo as |foo|}}\n            {{concat (foo bar baz)}}\n          {{/let}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` helper because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L3:C21) "); // Not shadowed

      (0, _index.compile)("\n        {{#let foo as |foo|}}{{/let}}\n        {{concat (foo)}}\n        {{concat (foo bar baz)}}", {
        moduleName: 'baz/foo-bar'
      }); // Not invocations

      (0, _index.compile)("\n        {{#let foo as |foo|}}\n          {{concat foo}}\n        {{/let}}", {
        moduleName: 'baz/foo-bar'
      });
      (0, _index.compile)("\n        {{#let (concat foo) as |concat|}}\n          {{input value=concat}}\n        {{/let}}", {
        moduleName: 'baz/foo-bar'
      });
    }

    ["@test element nodes shadowing sub-expression invocations"]() {
      expectAssertion(() => {
        (0, _index.compile)("\n          <Foo as |foo|>\n            {{concat (foo)}}\n          </Foo>", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` helper because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L3:C21) ");
      expectAssertion(() => {
        (0, _index.compile)("\n          <Foo as |foo|>\n            {{concat (foo bar baz)}}\n          </Foo>", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` helper because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L3:C21) "); // Not shadowed

      (0, _index.compile)("\n        <Foo as |foo|></Foo>\n        {{concat (foo)}}\n        {{concat (foo bar baz)}}", {
        moduleName: 'baz/foo-bar'
      }); // Not invocations

      (0, _index.compile)("\n        <Foo as |foo|>\n          {{concat foo}}\n        </Foo>", {
        moduleName: 'baz/foo-bar'
      });
      (0, _index.compile)("\n        <Foo foo={{concat foo}} as |concat|>\n          {{input value=concat}}\n        </Foo>", {
        moduleName: 'baz/foo-bar'
      });
    }

    ["@test deeply nested sub-expression invocations"]() {
      expectAssertion(() => {
        (0, _index.compile)("\n          {{#let foo as |foo|}}\n            <FooBar as |bar|>\n              {{#each items as |baz|}}\n                {{concat (foo)}}\n              {{/each}}\n            </FooBar>\n          {{/let}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` helper because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L5:C25) ");
      expectAssertion(() => {
        (0, _index.compile)("\n          {{#let foo as |foo|}}\n            <FooBar as |bar|>\n              {{#each items as |baz|}}\n                {{concat (foo bar baz)}}\n              {{/each}}\n            </FooBar>\n          {{/let}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` helper because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L5:C25) "); // Not shadowed

      (0, _index.compile)("\n        {{#let foo as |foo|}}\n          <FooBar as |bar|>\n            {{#each items as |baz|}}\n            {{/each}}\n            {{concat (baz)}}\n            {{concat (baz bat)}}\n          </FooBar>\n          {{concat (bar)}}\n          {{concat (bar baz bat)}}\n        {{/let}}\n        {{concat (foo)}}\n        {{concat (foo bar baz bat)}}", {
        moduleName: 'baz/foo-bar'
      }); // Not invocations

      (0, _index.compile)("\n        {{#let foo as |foo|}}\n          <FooBar as |bar|>\n            {{#each items as |baz|}}\n              {{concat foo}}\n            {{/each}}\n          </FooBar>\n        {{/let}}", {
        moduleName: 'baz/foo-bar'
      });
      (0, _index.compile)("\n        {{#let (foo foo) as |foo|}}\n          <FooBar bar=(bar bar) as |bar|>\n            {{#each (baz baz) as |baz|}}\n              {{concat foo bar baz}}\n            {{/each}}\n          </FooBar>\n        {{/let}}", {
        moduleName: 'baz/foo-bar'
      });
    }

    ["@test block statements shadowing attribute sub-expression invocations"]() {
      expectAssertion(() => {
        (0, _index.compile)("\n          {{#let foo as |foo|}}\n            <div class={{concat (foo bar baz)}} />\n          {{/let}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` helper because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L3:C32) "); // Not shadowed

      (0, _index.compile)("\n        {{#let foo as |foo|}}{{/let}}\n        <div class={{concat (foo)}} />\n        <div class={{concat (foo bar baz)}} />", {
        moduleName: 'baz/foo-bar'
      }); // Not invocations

      (0, _index.compile)("\n        {{#let foo as |foo|}}\n          <div class={{concat foo}} />\n        {{/let}}", {
        moduleName: 'baz/foo-bar'
      });
      (0, _index.compile)("\n        {{#let (foo foo) as |foo|}}\n          <div class={{concat foo}} />\n        {{/let}}", {
        moduleName: 'baz/foo-bar'
      });
    }

    ["@test element nodes shadowing attribute sub-expression invocations"]() {
      expectAssertion(() => {
        (0, _index.compile)("\n          <Foo as |foo|>\n            <div class={{concat (foo bar baz)}} />\n          </Foo>", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` helper because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L3:C32) "); // Not shadowed

      (0, _index.compile)("\n        <Foo as |foo|></Foo>\n        <div class={{concat (foo)}} />\n        <div class={{concat (foo bar baz)}} />", {
        moduleName: 'baz/foo-bar'
      }); // Not invocations

      (0, _index.compile)("\n        <Foo as |foo|>\n          <div class={{concat foo}} />\n        </Foo>", {
        moduleName: 'baz/foo-bar'
      });
      (0, _index.compile)("\n        <Foo foo={{foo foo}} as |foo|>\n          <div class={{concat foo}} />\n        </Foo>", {
        moduleName: 'baz/foo-bar'
      });
    }

    ["@test deeply nested attribute sub-expression invocations"]() {
      expectAssertion(() => {
        (0, _index.compile)("\n          {{#let foo as |foo|}}\n            <FooBar as |bar|>\n              {{#each items as |baz|}}\n                <div class={{concat (foo bar baz)}} />\n              {{/each}}\n            </FooBar>\n          {{/let}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` helper because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L5:C36) "); // Not shadowed

      (0, _index.compile)("\n        {{#let foo as |foo|}}\n          <FooBar as |bar|>\n            {{#each items as |baz|}}\n            {{/each}}\n            <div class={{concat (baz)}} />\n            <div class={{concat (baz bat)}} />\n          </FooBar>\n          <div class={{concat (bar)}} />\n          <div class={{concat (bar baz bat)}} />\n        {{/let}}\n        <div class={{concat (foo)}} />\n        <div class={{concat (foo bar baz bat)}} />", {
        moduleName: 'baz/foo-bar'
      }); // Not invocations

      (0, _index.compile)("\n        {{#let foo as |foo|}}\n          <FooBar as |bar|>\n            {{#each items as |baz|}}\n              <div class={{concat foo}} />\n            {{/each}}\n          </FooBar>\n        {{/let}}", {
        moduleName: 'baz/foo-bar'
      });
      (0, _index.compile)("\n        {{#let (foo foo) as |foo|}}\n          <FooBar bar=(bar bar) as |bar|>\n            {{#each (baz baz) as |baz|}}\n              <div class={{concat foo bar baz}} />\n            {{/each}}\n          </FooBar>\n        {{/let}}", {
        moduleName: 'baz/foo-bar'
      });
    }

    ["@test block statements shadowing attribute mustache invocations"]() {
      expectAssertion(() => {
        (0, _index.compile)("\n          {{#let foo as |foo|}}\n            <div class={{foo bar baz}} />\n          {{/let}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` helper because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L3:C23) "); // Not shadowed

      (0, _index.compile)("\n        {{#let foo as |foo|}}{{/let}}\n        <div class={{foo}} />\n        <div class={{foo bar baz}} />", {
        moduleName: 'baz/foo-bar'
      }); // Not invocations

      (0, _index.compile)("\n        {{#let foo as |foo|}}\n          <div class={{foo}} />\n        {{/let}}", {
        moduleName: 'baz/foo-bar'
      });
      (0, _index.compile)("\n        {{#let (concat foo) as |concat|}}\n          <div class={{concat}} />\n        {{/let}}", {
        moduleName: 'baz/foo-bar'
      });
    }

    ["@test element nodes shadowing attribute mustache invocations"]() {
      expectAssertion(() => {
        (0, _index.compile)("\n          <Foo as |foo|>\n            <div class={{foo bar baz}} />\n          </Foo>", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` helper because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L3:C23) "); // Not shadowed

      (0, _index.compile)("\n        <Foo as |foo|></Foo>\n        <div class={{foo}} />\n        <div class={{foo bar baz}} />", {
        moduleName: 'baz/foo-bar'
      }); // Not invocations

      (0, _index.compile)("\n        <Foo as |foo|>\n          <div class={{foo}} />\n        </Foo>", {
        moduleName: 'baz/foo-bar'
      });
      (0, _index.compile)("\n        <Foo foo={{concat foo}} as |concat|>\n          <div class={{concat}} />\n        </Foo>", {
        moduleName: 'baz/foo-bar'
      });
    }

    ["@test deeply nested attribute mustache invocations"]() {
      expectAssertion(() => {
        (0, _index.compile)("\n          {{#let foo as |foo|}}\n            <FooBar as |bar|>\n              {{#each items as |baz|}}\n                <div class={{foo bar baz}} />\n              {{/each}}\n            </FooBar>\n          {{/let}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` helper because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L5:C27) "); // Not shadowed

      (0, _index.compile)("\n        {{#let foo as |foo|}}\n          <FooBar as |bar|>\n            {{#each items as |baz|}}\n            {{/each}}\n            <div class={{baz}} />\n            <div class={{baz bat}} />\n          </FooBar>\n          <div class={{bar}} />\n          <div class={{bar baz bat}} />\n        {{/let}}\n        <div class={{foo}} />\n        <div class={{foo bar baz bat}} />", {
        moduleName: 'baz/foo-bar'
      }); // Not invocations

      (0, _index.compile)("\n        {{#let foo as |foo|}}\n          <FooBar as |bar|>\n            {{#each items as |baz|}}\n              <div class={{foo}} />\n            {{/each}}\n          </FooBar>\n        {{/let}}", {
        moduleName: 'baz/foo-bar'
      });
      (0, _index.compile)("\n        {{#let (foo foo) as |foo|}}\n          <FooBar bar=(bar bar) as |bar|>\n            {{#each (baz baz) as |baz|}}\n              <div foo={{foo}} bar={{bar}} baz={{baz}} />\n            {{/each}}\n          </FooBar>\n        {{/let}}", {
        moduleName: 'baz/foo-bar'
      });
    }

    ["@test block statements shadowing mustache invocations"](assert) {
      // These are fine, because they should already be considered contextual
      // component invocations, not helper invocations
      assert.expect(0);
      (0, _index.compile)("\n        {{#let foo as |foo|}}\n          {{foo}}\n        {{/let}}", {
        moduleName: 'baz/foo-bar'
      });
      (0, _index.compile)("\n        {{#let foo as |foo|}}\n          {{foo bar baz}}\n        {{/let}}", {
        moduleName: 'baz/foo-bar'
      });
    }

    ["@test element nodes shadowing mustache invocations"](assert) {
      // These are fine, because they should already be considered contextual
      // component invocations, not helper invocations
      assert.expect(0);
      (0, _index.compile)("\n        <Foo as |foo|>\n          {{foo}}\n        </Foo>", {
        moduleName: 'baz/foo-bar'
      });
      (0, _index.compile)("\n        <Foo as |foo|>\n          {{foo bar baz}}\n        </Foo>", {
        moduleName: 'baz/foo-bar'
      });
    }

    ["@test deeply nested mustache invocations"](assert) {
      // These are fine, because they should already be considered contextual
      // component invocations, not helper invocations
      assert.expect(0);
      (0, _index.compile)("\n        {{#let foo as |foo|}}\n          <FooBar as |bar|>\n            {{#each items as |baz|}}\n              {{foo}}\n            {{/each}}\n          </FooBar>\n        {{/let}}", {
        moduleName: 'baz/foo-bar'
      });
      (0, _index.compile)("\n        {{#let foo as |foo|}}\n          <FooBar as |bar|>\n            {{#each items as |baz|}}\n              {{foo bar baz}}\n            {{/each}}\n          </FooBar>\n        {{/let}}", {
        moduleName: 'baz/foo-bar'
      });
    }

    ["@test block statements shadowing modifier invocations"]() {
      expectAssertion(() => {
        (0, _index.compile)("\n          {{#let foo as |foo|}}\n            <div {{foo}} />\n          {{/let}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` modifier because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L3:C17) ");
      expectAssertion(() => {
        (0, _index.compile)("\n          {{#let foo as |foo|}}\n            <div {{foo bar baz}} />\n          {{/let}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` modifier because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L3:C17) "); // Not shadowed

      (0, _index.compile)("\n        {{#let foo as |foo|}}{{/let}}\n        <div {{foo}} />\n        <div {{foo bar baz}} />", {
        moduleName: 'baz/foo-bar'
      });
    }

    ["@test element nodes shadowing modifier invocations"]() {
      expectAssertion(() => {
        (0, _index.compile)("\n          <Foo as |foo|>\n            <div {{foo}} />\n          </Foo>", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` modifier because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L3:C17) ");
      expectAssertion(() => {
        (0, _index.compile)("\n          <Foo as |foo|>\n            <div {{foo bar baz}} />\n          </Foo>", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` modifier because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L3:C17) "); // Not shadowed

      (0, _index.compile)("\n        <Foo as |foo|></Foo>\n        <div {{foo}} />\n        <div {{foo bar baz}} />", {
        moduleName: 'baz/foo-bar'
      });
    }

    ["@test deeply nested modifier invocations"]() {
      expectAssertion(() => {
        (0, _index.compile)("\n          {{#let foo as |foo|}}\n            <FooBar as |bar|>\n              {{#each items as |baz|}}\n                <div {{foo}} />\n              {{/each}}\n            </FooBar>\n          {{/let}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` modifier because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L5:C21) ");
      expectAssertion(() => {
        (0, _index.compile)("\n          {{#let foo as |foo|}}\n            <FooBar as |bar|>\n              {{#each items as |baz|}}\n                <div {{foo bar baz}} />\n              {{/each}}\n            </FooBar>\n          {{/let}}", {
          moduleName: 'baz/foo-bar'
        });
      }, "Cannot invoke the `foo` modifier because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict. ('baz/foo-bar' @ L5:C21) "); // Not shadowed

      (0, _index.compile)("\n        {{#let foo as |foo|}}\n          <FooBar as |bar|>\n            {{#each items as |baz|}}\n            {{/each}}\n            <div {{baz}} />\n            <div {{baz bat}} />\n          </FooBar>\n          <div {{bar}} />\n          <div {{bar baz bat}} />\n        {{/let}}\n        <div {{foo}} />\n        <div {{foo bar baz bat}} />", {
        moduleName: 'baz/foo-bar'
      });
    }

  });
});