define("shared/components/schema/input-storageclass/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mxJ5DRC5",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"searchable-select\",null,[[\"content\",\"class\",\"value\",\"prompt\",\"optionValuePath\",\"optionLabelPath\",\"placeholder\"],[[24,[\"storageClassesOptions\"]],\"form-control\",[24,[\"selected\"]],[28,\"t\",[\"schema.inputStorageClass.prompt\"],null],\"id\",\"displayName\",[28,\"t\",[\"schema.inputStorageClass.prompt\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/schema/input-storageclass/template.hbs"
    }
  });

  _exports.default = _default;
});