define("global-admin/components/form-global-roles-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v6TwZn0g",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"label\",true],[10,\"class\",\"acc-label\"],[10,\"style\",\"min-height: 50px;\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[24,[\"model\",\"active\"]]]]],false],[0,\" \"],[4,\"if\",[[24,[\"model\",\"hasTranslation\"]]],null,{\"statements\":[[1,[28,\"t\",[[24,[\"model\",\"translationKey\"]]],null],false]],\"parameters\":[]},{\"statements\":[[1,[24,[\"model\",\"role\",\"displayName\"]],false]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"help-block\"],[8],[4,\"if\",[[24,[\"model\",\"hasTranslation\"]]],null,{\"statements\":[[1,[28,\"t\",[[24,[\"model\",\"descriptionTranslationKey\"]]],null],false]],\"parameters\":[]},{\"statements\":[[1,[24,[\"model\",\"role\",\"detail\"]],false]],\"parameters\":[]}],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/components/form-global-roles-row/template.hbs"
    }
  });

  _exports.default = _default;
});