define("global-admin/components/other-role-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IEAzNqBg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[8],[0,\"\\n  \"],[1,[28,\"searchable-select\",null,[[\"content\",\"value\",\"readOnly\"],[[24,[\"choices\"]],[24,[\"model\",\"roleId\"]],[24,[\"readOnly\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\" \"],[9],[0,\"\\n\"],[4,\"unless\",[[24,[\"readOnly\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"input-group-btn\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn bg-primary btn-sm\"],[3,\"action\",[[23,0,[]],\"remove\"]],[8],[7,\"i\",true],[10,\"class\",\"icon icon-minus\"],[8],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/components/other-role-row/template.hbs"
    }
  });

  _exports.default = _default;
});