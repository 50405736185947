define("shared/components/schema/input-certificate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7rNGH3DG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"loading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"icon icon-spinner icon-spin\"],[8],[9],[0,\" \"],[1,[28,\"t\",[\"generic.loading\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"new-select\",null,[[\"classNames\",\"content\",\"prompt\",\"localizedPrompt\",\"optionLabelPath\",\"optionValuePath\",\"value\"],[\"form-control\",[24,[\"choices\"]],\"schema.inputCertificate.prompt\",true,\"displayDetailedName\",\"name\",[24,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/schema/input-certificate/template.hbs"
    }
  });

  _exports.default = _default;
});