define("shared/components/copy-to-clipboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QErZDvGa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"isSupported\"]]],null,{\"statements\":[[4,\"tooltip-element\",null,[[\"type\",\"model\",\"tooltipTemplate\",\"class\",\"textChangedEvent\",\"baseClass\",\"isCopyTo\",\"tooltipFor\"],[\"tooltip-basic\",[28,\"t\",[[28,\"if\",[[24,[\"model\"]],[24,[\"model\",\"tooltipText\"]],\"generic.emptyString\"],null]],null],\"tooltip-static\",\"copy-button-container bg-transparent\",[24,[\"textChangedEvent\"]],\"copy-clipboard\",true,\"copyToClipboard\"]],{\"statements\":[[4,\"copy-button\",null,[[\"class\",\"clipboardTarget\",\"clipboardText\",\"success\"],[[24,[\"buttonClasses\"]],[24,[\"target\"]],[24,[\"clipboardText\"]],\"alertSuccess\"]],{\"statements\":[[0,\"      \"],[14,1],[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\" \"],[7,\"i\",true],[11,\"class\",[29,[\"icon \",[22,\"icon\"]]]],[8],[9]],\"parameters\":[]},null],[4,\"if\",[[24,[\"buttonText\"]]],null,{\"statements\":[[0,\" \"],[1,[28,\"t\",[[24,[\"buttonText\"]]],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/copy-to-clipboard/template.hbs"
    }
  });

  _exports.default = _default;
});