define("shared/components/modal-confirm-deactivate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fezxqF2n",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"protip\"],[8],[1,[28,\"t\",[\"modalConfirmDeactivate.protip\"],[[\"key\",\"isServiceButton\"],[[24,[\"alternateLabel\"]],[24,[\"isService\",\"button\"]]]]],false],[9],[0,\"\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isClusterDriver\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"mb-20 mt-20\"],[8],[0,\"\\n\"],[4,\"banner-message\",null,[[\"color\"],[\"bg-warning m-0\"]],{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"modalConfirmDeactivate.clusterDriver\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"isNodeDriver\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"mb-20 mt-20\"],[8],[0,\"\\n\"],[4,\"banner-message\",null,[[\"color\"],[\"bg-warning m-0\"]],{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"modalConfirmDeactivate.nodeDriver\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"container-header-text\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"modalConfirmDeactivate.header\"],null],false],[0,\" \"],[1,[24,[\"isService\",\"message\"]],false],[0,\":\\n  \"],[7,\"div\",true],[10,\"class\",\"display-name\"],[8],[0,\"\\n    \"],[1,[24,[\"originalModel\",\"displayName\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[7,\"div\",true],[10,\"class\",\"footer-actions\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn bg-error\"],[3,\"action\",[[23,0,[]],\"confirm\"]],[8],[1,[24,[\"isService\",\"button\"]],false],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn bg-transparent\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[1,[28,\"t\",[\"modalConfirmDeactivate.cancel\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/modal-confirm-deactivate/template.hbs"
    }
  });

  _exports.default = _default;
});