define("global-admin/security/roles/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6DuwYqy+",
    "block": "{\"symbols\":[],\"statements\":[[5,\"new-edit-role\",[],[[\"@model\",\"@mode\",\"@roleType\"],[[22,\"model\"],\"edit\",[22,\"type\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/security/roles/edit/template.hbs"
    }
  });

  _exports.default = _default;
});