define("shared/components/modal-confirm-yaml-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P1y0XXed",
    "block": "{\"symbols\":[\"row\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-header-text\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"modalConfirmYaml2Form.header\"],null],false],[0,\":\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"protip\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"modalConfirmYaml2Form.helpText\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"hr\",true],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"display-name\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"mb-5\"],[8],[0,\"\\n        \"],[7,\"code\",true],[8],[0,\"\\n          \"],[1,[23,1,[\"lostKey\"]],false],[0,\": \"],[1,[23,1,[\"lostValue\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"footer-actions\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn bg-error\"],[3,\"action\",[[23,0,[]],\"confirm\"]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"modalConfirmYaml2Form.confirm\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn bg-transparent\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"generic.cancel\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/modal-confirm-yaml-switch/template.hbs"
    }
  });

  _exports.default = _default;
});