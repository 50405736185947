define("shared/components/search-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tlnQTEwh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"pull-right search-group input-group\"],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"value\",\"aria-title\",\"type\",\"class\",\"placeholder\"],[[24,[\"searchText\"]],[28,\"t\",[\"generic.search\"],null],\"search\",\"input-sm pull-right\",[28,\"t\",[\"generic.search\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"searchText\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"input-group-btn\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn bg-transparent text-info pl-10 pr-10\"],[3,\"action\",[[23,0,[]],\"clearSearch\"]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"icon icon-close\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/search-text/template.hbs"
    }
  });

  _exports.default = _default;
});