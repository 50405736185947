define("shared/components/cru-cluster-template-questions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yimvTZ9j",
    "block": "{\"symbols\":[\"sortable\",\"kind\",\"question\",\"dt\"],\"statements\":[[5,\"sortable-table\",[[12,\"classNames\",\"grid sortable-table\"]],[[\"@body\",\"@bulkActions\",\"@descending\",\"@headers\",\"@pagingLabel\",\"@searchText\",\"@sortBy\",\"@stickyHeader\"],[[22,\"rows\"],[22,\"bulkActions\"],[22,\"descending\"],[22,\"questionsHeaders\"],\"pagination.member\",[22,\"searchText\"],[22,\"sortBy\"],true]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,2,[]],\"row\"],null]],null,{\"statements\":[[0,\"    \"],[5,\"cluster-template-question-row\",[],[[\"@questions\",\"@question\",\"@removeQuestion\",\"@applyClusterTemplate\",\"@clusterTemplateCreate\"],[[22,\"schemaQuestions\"],[23,3,[]],[22,\"removeQuestion\"],[22,\"applyClusterTemplate\"],[22,\"clusterTemplateCreate\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[]],\"nomatch\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"td\",true],[10,\"colspan\",\"5\"],[10,\"class\",\"no-match\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"clusterTemplateQuestions.table.noMatch\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[]],\"norows\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"td\",true],[10,\"colspan\",\"5\"],[10,\"class\",\"no-data\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"clusterTemplateQuestions.table.noData\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1,2,3,4]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/cru-cluster-template-questions/template.hbs"
    }
  });

  _exports.default = _default;
});