define("shared/mixins/cattle-transitioning-resource", ["exports", "ui/utils/util", "ui/utils/constants", "shared/utils/download-files"], function (_exports, _util, _constants, _downloadFiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function terminatedIcon(inst) {
    if (Ember.get(inst, 'exitCode') === 0) {
      return 'icon icon-dot-circlefill';
    } else {
      return 'icon icon-circle';
    }
  }

  function terminatedColor(inst) {
    if (Ember.get(inst, 'exitCode') === 0) {
      return 'text-success';
    } else {
      return 'text-error';
    }
  }

  var defaultStateMap = {
    'aborted': {
      icon: 'icon icon-alert',
      color: 'text-warning'
    },
    'activating': {
      icon: 'icon icon-tag',
      color: 'text-info'
    },
    'active': {
      icon: 'icon icon-circle-o',
      color: 'text-success'
    },
    'available': {
      icon: 'icon icon-circle-o',
      color: 'text-success'
    },
    'bound': {
      icon: 'icon icon-circle',
      color: 'text-success'
    },
    'backedup': {
      icon: 'icon icon-backup',
      color: 'text-success'
    },
    'building': {
      icon: 'icon icon-circle-o',
      color: 'text-success'
    },
    'created': {
      icon: 'icon icon-tag',
      color: 'text-info'
    },
    'creating': {
      icon: 'icon icon-tag',
      color: 'text-info'
    },
    'denied': {
      icon: 'icon icon-adjust',
      color: 'text-error'
    },
    'deactivating': {
      icon: 'icon icon-adjust',
      color: 'text-info'
    },
    'degraded': {
      icon: 'icon icon-alert',
      color: 'text-warning'
    },
    'disconnected': {
      icon: 'icon icon-alert',
      color: 'text-warning'
    },
    'disabled': {
      icon: 'icon icon-alert',
      color: 'text-warning'
    },
    'error': {
      icon: 'icon icon-alert',
      color: 'text-error'
    },
    'erroring': {
      icon: 'icon icon-alert',
      color: 'text-error'
    },
    'expired': {
      icon: 'icon icon-alert',
      color: 'text-warning'
    },
    'fail': {
      icon: 'icon icon-alert',
      color: 'text-error'
    },
    'failed': {
      icon: 'icon icon-alert',
      color: 'text-error'
    },
    'healthy': {
      icon: 'icon icon-circle-o',
      color: 'text-success'
    },
    'locked': {
      icon: 'icon icon-adjust',
      color: 'text-warning'
    },
    'inactive': {
      icon: 'icon icon-circle',
      color: 'text-error'
    },
    'initializing': {
      icon: 'icon icon-alert',
      color: 'text-warning'
    },
    'migrating': {
      icon: 'icon icon-info',
      color: 'text-info'
    },
    'pass': {
      icon: 'icon icon-circle-o',
      color: 'text-success'
    },
    'paused': {
      icon: 'icon icon-info',
      color: 'text-info'
    },
    'provisioning': {
      icon: 'icon icon-circle',
      color: 'text-info'
    },
    'pending': {
      icon: 'icon icon-tag',
      color: 'text-info'
    },
    'purged': {
      icon: 'icon icon-purged',
      color: 'text-error'
    },
    'purging': {
      icon: 'icon icon-purged',
      color: 'text-info'
    },
    'reconnecting': {
      icon: 'icon icon-alert',
      color: 'text-error'
    },
    'registering': {
      icon: 'icon icon-tag',
      color: 'text-info'
    },
    'released': {
      icon: 'icon icon-alert',
      color: 'text-warning'
    },
    'reinitializing': {
      icon: 'icon icon-alert',
      color: 'text-warning'
    },
    'removed': {
      icon: 'icon icon-trash',
      color: 'text-error'
    },
    'removing': {
      icon: 'icon icon-trash',
      color: 'text-info'
    },
    'requested': {
      icon: 'icon icon-tag',
      color: 'text-info'
    },
    'restarting': {
      icon: 'icon icon-adjust',
      color: 'text-info'
    },
    'restoring': {
      icon: 'icon icon-medicalcross',
      color: 'text-info'
    },
    'running': {
      icon: 'icon icon-circle-o',
      color: 'text-success'
    },
    'starting': {
      icon: 'icon icon-adjust',
      color: 'text-info'
    },
    'stopped': {
      icon: 'icon icon-circle',
      color: 'text-error'
    },
    'stopping': {
      icon: 'icon icon-adjust',
      color: 'text-info'
    },
    'succeeded': {
      icon: 'icon icon-dot-circlefill',
      color: 'text-success'
    },
    'success': {
      icon: 'icon icon-circle-o',
      color: 'text-success'
    },
    'suspended': {
      icon: 'icon icon-pause',
      color: 'text-info'
    },
    'skipped': {
      icon: 'icon icon-circle-o',
      color: 'text-info'
    },
    'terminated': {
      icon: terminatedIcon,
      color: terminatedColor
    },
    'unavailable': {
      icon: 'icon icon-alert',
      color: 'text-error'
    },
    'unhealthy': {
      icon: 'icon icon-alert',
      color: 'text-error'
    },
    'unknown': {
      icon: 'icon icon-help',
      color: 'text-warning'
    },
    'untriggered': {
      icon: 'icon icon-tag',
      color: 'text-success'
    },
    'updating': {
      icon: 'icon icon-tag',
      color: 'text-warning'
    },
    'upgrading': {
      icon: 'icon icon-tag',
      color: 'text-warning'
    },
    'waiting': {
      icon: 'icon icon-tag',
      color: 'text-info'
    }
  };
  var stateColorSortMap = {
    'error': 1,
    'warning': 2,
    'info': 3,
    'success': 4,
    'other': 5
  };

  var _default = Ember.Mixin.create({
    endpointSvc: Ember.inject.service('endpoint'),
    // Some machine drivers have a property called 'endpoint'
    cookies: Ember.inject.service(),
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    reservedKeys: ['waitInterval', 'waitTimeout'],
    state: null,
    transitioning: null,
    transitioningMessage: null,
    transitioningProgress: null,
    availableActions: Ember.computed(function () {
      /*
        For custom actions not in _availableActions below, Override me and return [
          {
            enabled: true/false,    // Whether it's shown or not.  Anything other than exactly false will be shown.
            bulkable: true/false,   // If true, the action is shown in bulk actions on sortable-tables
            single: true/false,     // If exactly false, the action is not shown on individual resource actions (with bulkable=true for a bulk-only action)
            label: 'Delete',        // Label shown on hover or in menu
            icon: 'icon icon-trash',// Icon shown on screen
            action: 'promptDelete', // Action to call on the controller when clicked
            altAction: 'delete'     // Action to call on the controller when alt+clicked
            divider: true,          // Just this will make a divider
          },
          ...
        ]
      */
      return [];
    }),
    _availableActions: Ember.computed('availableActions.[]', 'links.{self,yaml}', 'canEdit', 'canEditYaml', 'canViewYaml', 'canRemove', 'grafanaUrl', function () {
      var out = Ember.get(this, 'availableActions').slice();
      var nextSort = 1;
      out.forEach(function (entry) {
        if (!entry.sort) {
          entry.sort = nextSort++;
        }
      });
      var l = Ember.get(this, 'links') || {};
      out.push({
        sort: -99,
        label: 'action.edit',
        icon: 'icon icon-edit',
        action: 'edit',
        enabled: Ember.get(this, 'canEdit')
      });
      out.push({
        sort: -98,
        label: 'action.clone',
        action: 'clone',
        icon: 'icon icon-copy',
        enabled: Ember.get(this, 'canClone')
      }); // Normal actions go here in the sort order

      out.push({
        sort: 94,
        divider: true
      });
      out.push({
        sort: 95,
        label: 'action.editYaml',
        icon: 'icon icon-edit',
        action: 'editYaml',
        enabled: !!l.yaml && Ember.get(this, 'canEditYaml')
      });
      out.push({
        sort: 96,
        label: 'action.viewYaml',
        icon: 'icon icon-file',
        action: 'viewYaml',
        enabled: Ember.get(this, 'canViewYaml')
      });
      out.push({
        sort: 97,
        label: 'action.downloadYaml',
        icon: 'icon icon-download',
        action: 'downloadYaml',
        bulkable: true,
        single: false,
        enabled: Ember.get(this, 'canDownloadYaml')
      });
      out.push({
        sort: 98,
        label: 'action.viewInApi',
        icon: 'icon icon-external-link',
        action: 'goToApi',
        enabled: !!l.self
      });
      out.push({
        sort: 99,
        label: 'action.viewInGrafana',
        icon: 'icon icon-link',
        action: 'goToGrafana',
        enabled: !!Ember.get(this, 'grafanaUrl')
      });
      out.push({
        sort: 100,
        divider: true
      });
      out.push({
        sort: 101,
        label: 'action.remove',
        icon: 'icon icon-trash',
        action: 'promptDelete',
        altAction: Ember.get(this, 'getAltActionDelete'),
        bulkable: Ember.get(this, 'canBulkRemove'),
        enabled: Ember.get(this, 'canRemove')
      });
      return out.sortBy('sort');
    }),
    getAltActionDelete: Ember.computed('action.remove', function () {
      // eslint-disable-line
      // eks clusters with session tokens can't be deleted with alt actions because of the verification of keys that needs to occur
      return 'delete';
    }),
    canBulkRemove: Ember.computed('action.remove', function () {
      // eslint-disable-line
      return true;
    }),
    canClone: Ember.computed('actions.clone', function () {
      return !!Ember.get(this, 'actions.clone');
    }),
    canEditYaml: Ember.computed.alias('canEdit'),
    canViewYaml: Ember.computed('links.@each', 'canEditYaml', function () {
      return !!Ember.get(this, 'links.yaml') && !Ember.get(this, 'canEditYaml');
    }),
    canDownloadYaml: Ember.computed('links.@each', function () {
      return !!Ember.get(this, 'links.yaml');
    }),
    canEdit: Ember.computed('links.@each', 'actions.edit', function () {
      return !!Ember.get(this, 'links.update') && !!Ember.get(this, 'actions.edit');
    }),
    canRemove: Ember.computed('links.@each', function () {
      return !!Ember.get(this, 'links.remove');
    }),
    actions: {
      promptDelete: function promptDelete() {
        Ember.get(this, 'modalService').toggleModal('confirm-delete', {
          escToClose: true,
          resources: [this]
        });
      },
      delete: function _delete() {
        return this.delete();
      },
      downloadYaml: function downloadYaml() {
        (0, _downloadFiles.downloadResourceYaml)([this]);
      },
      editYaml: function editYaml() {
        Ember.get(this, 'modalService').toggleModal('modal-yaml', {
          escToClose: true,
          resource: this
        });
      },
      viewYaml: function viewYaml() {
        Ember.get(this, 'modalService').toggleModal('modal-yaml', {
          escToClose: true,
          resource: this,
          readOnly: true
        });
      },
      goToApi: function goToApi() {
        var url = Ember.get(this, 'links.self'); // http://a.b.c.d/v1/things/id, a.b.c.d is where the UI is running

        window.open(url, '_blank');
      },
      goToGrafana: function goToGrafana() {
        var url = Ember.get(this, 'grafanaUrl');
        window.open(url, '_blank');
      }
    },
    displayName: Ember.computed('name', 'id', function () {
      return Ember.get(this, 'name') || "(".concat(Ember.get(this, 'id'), ")");
    }),
    sortName: Ember.computed('displayName', function () {
      return (0, _util.sortableNumericSuffix)(Ember.get(this, 'displayName').toLowerCase());
    }),
    isTransitioning: Ember.computed.equal('transitioning', 'yes'),
    isError: Ember.computed.equal('transitioning', 'error'),
    isActive: Ember.computed.equal('state', 'active'),
    relevantState: Ember.computed('combinedState', 'state', function () {
      return Ember.get(this, 'combinedState') || Ember.get(this, 'state') || 'unknown';
    }),
    // This is like this so you can override the displayed state calculation
    displayState: Ember.computed.alias('_displayState'),
    _displayState: Ember.computed('relevantState', 'intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var state = Ember.get(this, 'relevantState') || '';
      var key = "resourceState.".concat((state || 'unknown').toLowerCase());

      if (intl.locale && intl.exists(key)) {
        return intl.t(key);
      }

      return state.split(/-/).map(function (word) {
        return (0, _util.ucFirst)(word);
      }).join('-');
    }),
    showTransitioningMessage: Ember.computed('transitioning', 'transitioningMessage', 'displayState', function () {
      var trans = Ember.get(this, 'transitioning');

      if (trans === 'yes' || trans === 'error') {
        var message = Ember.get(this, 'transitioningMessage') || '';

        if (message.length && message.toLowerCase() !== Ember.get(this, 'displayState').toLowerCase()) {
          return true;
        }
      }

      return false;
    }),
    stateIcon: Ember.computed('relevantState', 'transitioning', function () {
      var trans = Ember.get(this, 'transitioning');
      var icon = '';

      if (trans === 'yes') {
        icon = 'icon icon-spinner icon-spin';
      } else if (trans === 'error') {
        icon = 'icon icon-alert';
      } else {
        var map = this.constructor.stateMap;
        var key = (Ember.get(this, 'relevantState') || '').toLowerCase();

        if (map && map[key] && map[key].icon !== undefined) {
          if (typeof map[key].icon === 'function') {
            icon = map[key].icon(this);
          } else {
            icon = map[key].icon;
          }
        }

        if (!icon && defaultStateMap[key] && defaultStateMap[key].icon) {
          var tmp = defaultStateMap[key].icon;

          if (typeof tmp === 'function') {
            icon = tmp(this);
          } else {
            icon = tmp;
          }
        }

        if (!icon) {
          icon = this.constructor.defaultStateIcon;
        }

        if (icon.indexOf('icon ') === -1) {
          icon = "icon ".concat(icon);
        }
      }

      return icon;
    }),
    stateColor: Ember.computed('relevantState', 'isError', function () {
      if (Ember.get(this, 'isError')) {
        return 'text-error';
      }

      var map = this.constructor.stateMap;
      var key = (Ember.get(this, 'relevantState') || '').toLowerCase();

      if (map && map[key] && map[key].color !== undefined) {
        if (typeof map[key].color === 'function') {
          return map[key].color(this);
        } else {
          return map[key].color;
        }
      }

      if (defaultStateMap[key] && defaultStateMap[key].color) {
        var tmp = defaultStateMap[key].color;

        if (typeof tmp === 'function') {
          return tmp(this);
        } else {
          return tmp;
        }
      }

      return this.constructor.defaultStateColor;
    }),
    sortState: Ember.computed('stateColor', 'relevantState', function () {
      var color = Ember.get(this, 'stateColor').replace('text-', '');
      return "".concat(stateColorSortMap[color] || stateColorSortMap['other'], " ").concat(Ember.get(this, 'relevantState'));
    }),
    stateBackground: Ember.computed('stateColor', function () {
      return Ember.get(this, 'stateColor').replace('text-', 'bg-');
    }),
    cloneForNew: function cloneForNew() {
      var copy = this.clone();
      delete copy.actionLinks;
      delete copy.appId;
      delete copy.id;
      delete copy.links;
      delete copy.name;
      delete copy.uuid;
      return copy;
    },
    serializeForNew: function serializeForNew() {
      var copy = this.serialize();
      delete copy.id;
      delete copy.actionLinks;
      delete copy.links;
      delete copy.uuid;
      return copy;
    },
    // Show growls for errors on actions
    delete: function _delete()
    /* arguments*/
    {
      var _this = this;

      var promise = this._super.apply(this, arguments);

      return promise.catch(function (err) {
        Ember.get(_this, 'growl').fromError('Error deleting', err);
      });
    },
    doAction: function doAction(name, data, opt) {
      var _this2 = this;

      var promise = this._super.apply(this, arguments);

      if (!opt || opt.catchGrowl !== false) {
        return promise.catch(function (err) {
          Ember.get(_this2, 'growl').fromError("".concat((0, _util.ucFirst)(name), " Error"), err);
          return Ember.RSVP.reject(err);
        });
      }

      return promise;
    },
    // You really shouldn't have to use any of these.
    // Needing these is a sign that the API is bad and should feel bad.
    // Yet here they are, nonetheless.
    waitInterval: 1000,
    waitTimeout: 30000,
    _waitForTestFn: function _waitForTestFn(testFn, msg) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        // Do a first check immediately
        if (testFn.apply(_this3)) {
          resolve(_this3);
          return;
        }

        var timeout = setTimeout(function () {
          clearInterval(interval);
          clearTimeout(timeout);
          reject("Failed while: ".concat(msg));
        }, Ember.get(_this3, 'waitTimeout'));
        var interval = setInterval(function () {
          if (testFn.apply(_this3)) {
            clearInterval(interval);
            clearTimeout(timeout);
            resolve(_this3);
          }
        }, Ember.get(_this3, 'waitInterval'));
      }, msg || 'Wait for it...');
    },
    waitForState: function waitForState(state) {
      return this._waitForTestFn(function () {
        return Ember.get(this, 'state') === state;
      }, "Wait for state=".concat(state));
    },
    waitForTransition: function waitForTransition() {
      return this._waitForTestFn(function () {
        return Ember.get(this, 'transitioning') !== 'yes';
      }, 'Wait for transition');
    },
    waitForAction: function waitForAction(name) {
      return this._waitForTestFn(function () {
        // console.log('waitForAction('+name+'):', this.hasAction(name));
        return this.hasAction(name);
      }, "Wait for action=".concat(name));
    },
    hasCondition: function hasCondition(condition) {
      var status = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'True';
      var entry = (Ember.get(this, 'conditions') || []).findBy('type', condition);

      if (!entry) {
        return false;
      }

      if (status) {
        return (Ember.get(entry, 'status') || '').toLowerCase() === "".concat(status).toLowerCase();
      } else {
        return true;
      }
    },
    waitForCondition: function waitForCondition(condition) {
      var status = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'True';
      return this._waitForTestFn(function () {
        return this.hasCondition(condition, status);
      }, "Wait for Condition: ".concat(condition, ": ").concat(status));
    },
    displayUserLabelStrings: Ember.computed('labels', function () {
      var out = [];
      var labels = Ember.get(this, 'labels') || {};
      Object.keys(labels).forEach(function (key) {
        if (key.indexOf(_constants.default.LABEL.AFFINITY_PREFIX) === 0 || key.indexOf(_constants.default.LABEL.SYSTEM_PREFIX) === 0 || _constants.default.LABELS_TO_IGNORE.indexOf(key) >= 0) {
          // Skip ignored labels
          return;
        } else {
          for (var i = 0; i < _constants.default.LABEL_PREFIX_TO_IGNORE.length; i++) {
            if (key.startsWith(_constants.default.LABEL_PREFIX_TO_IGNORE[i])) {
              return;
            }
          }
        }

        out.push(key + (labels[key] ? "=".concat(labels[key]) : ''));
      });
      return out;
    }),
    displayTaintsStrings: Ember.computed('nodeTaints', 'taints', function () {
      var out = [];
      var taints = Ember.get(this, 'nodeTaints') || Ember.get(this, 'taints') || [];
      taints.forEach(function (taint) {
        out.push("".concat(Ember.get(taint, 'key')).concat(Ember.get(taint, 'value') ? "=".concat(Ember.get(taint, 'value')) : '', ":").concat(Ember.get(taint, 'effect')));
      });
      return out.sort();
    })
  });

  _exports.default = _default;
});