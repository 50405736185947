define("global-admin/security/accounts/new/controller", ["exports", "ui/mixins/new-or-edit", "ui/utils/constants"], function (_exports, _newOrEdit, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HEADERS = [{
    name: 'active',
    sort: ['active'],
    translationKey: 'accountsPage.detail.table.headers.active',
    width: 125
  }, {
    name: 'name',
    sort: ['name'],
    translationKey: 'accountsPage.detail.table.headers.role'
  }];

  var _default = Ember.Controller.extend(_newOrEdit.default, {
    globalStore: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    sortBy: 'name',
    globalRoleSave: null,
    canUserLogIn: null,
    headers: HEADERS,
    primaryResource: Ember.computed.alias('model.account'),
    actions: {
      cancel: function cancel() {
        this.transitionToRoute('security.accounts.users');
      },
      setGlobalRoleSave: function setGlobalRoleSave(fn) {
        Ember.set(this, 'globalRoleSave', fn);
      },
      setValidateGlobalRoles: function setValidateGlobalRoles(fn) {
        Ember.set(this, 'canUserLogIn', fn);
      }
    },
    validateDescription: Ember.computed(function () {
      return Ember.get(this, 'settings').get(_constants.default.SETTING.AUTH_LOCAL_VALIDATE_DESC) || null;
    }),
    roles: Ember.computed('model.globalRoles.[]', function () {
      return Ember.get(this, 'model.globalRoles').map(function (grb) {
        return {
          name: Ember.get(grb, 'name'),
          active: false,
          globalId: Ember.get(grb, 'id')
        };
      });
    }),
    doesExist: function doesExist() {
      var users = Ember.get(this, 'model.users');
      var account = Ember.get(this, 'model.account');

      if (users.findBy('username', account.get('username'))) {
        return true;
      }

      return false;
    },
    validate: function validate() {
      var errors = [];

      if (this.canUserLogIn && !this.canUserLogIn()) {
        errors.push(this.intl.t('formGlobalRoles.loginError', {
          type: this.intl.t('generic.user')
        }));
      }

      if ((Ember.get(this, 'model.account.username') || '').trim().length === 0) {
        errors.push(Ember.get(this, 'intl').t('accountsPage.new.errors.usernameReq'));
      }

      if (this.doesExist()) {
        errors.push(Ember.get(this, 'intl').t('accountsPage.new.errors.usernameInExists'));
      }

      if ((Ember.get(this, 'model.account.password') || '').trim().length === 0) {
        errors.push(Ember.get(this, 'intl').t('accountsPage.new.errors.pwReq'));
      }

      if (errors.length) {
        Ember.set(this, 'errors', errors.uniq());
        return false;
      } else {
        Ember.set(this, 'errors', null);
      }

      return true;
    },
    didSave: function didSave() {
      return this.globalRoleSave();
    },
    doneSaving: function doneSaving() {
      this.transitionToRoute('security.accounts.users');
    }
  });

  _exports.default = _default;
});