define("shared/components/answer-override-row/component", ["exports", "shared/components/answer-override-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    tagName: '',
    addSubrowQuestions: null,
    selectedTemplateModel: null,
    removeAnswerOverride: null,
    answer: null,
    ownedSubquestions: null,
    subquestionAnswers: null,
    answerOverrides: null,
    actions: {
      removeOverride: function removeOverride(answer) {
        var _this = this;

        if (this.subquestionAnswers) {
          this.removeSubquestionsAndSend(this.subquestionAnswers);
        }

        Ember.run.next(function () {
          Ember.get(_this, 'removeAnswerOverride')(answer);
        });
      }
    },
    scopeChanged: Ember.observer('answer.scope', function () {
      var subquestionAnswers = this.subquestionAnswers || [];
      var answer = this.answer;

      if (subquestionAnswers.length > 0) {
        subquestionAnswers.forEach(function (sq) {
          return Ember.set(sq, 'scope', Ember.get(answer, 'scope'));
        });
      }
    }),
    allProjectsAndClusters: Ember.computed('scope.allProjects.[]', 'scope.allClusters.[]', 'primaryResource.targets.@each.projectId', function () {
      var _this2 = this;

      var out = [];
      Ember.get(this, 'scope.allClusters').forEach(function (c) {
        out.pushObject({
          name: _this2.intl.t('newMultiClusterApp.overrides.dropdown.allProjects', {
            clusterName: c.name
          }),
          value: c.id,
          group: _this2.intl.t('newMultiClusterApp.overrides.dropdown.clusterGroup', {
            clusterName: c.name
          }),
          isCluster: true
        });
        c.get('projects').forEach(function (p) {
          out.pushObject({
            name: p.name,
            value: p.id,
            group: _this2.intl.t('newMultiClusterApp.overrides.dropdown.clusterGroup', {
              clusterName: c.name
            }),
            isProject: true
          });
        });
      });
      return out;
    }),
    allQuestions: Ember.computed('selectedTemplateModel.questions.[]', 'answer.answer', function () {
      var _this3 = this;

      var allQuestions = Ember.get(this, 'selectedTemplateModel.questions');
      var answer = this.answer;
      var questionMatch;

      if (!allQuestions) {
        var questionAnswers = Ember.get(this, 'primaryResource.answers.firstObject.values');
        allQuestions = Object.keys(questionAnswers).map(function (q) {
          return {
            label: q,
            variable: q
          };
        });
      }

      questionMatch = allQuestions.findBy('variable', Ember.get(answer, 'question'));
      var nueQuestions = [];
      allQuestions.forEach(function (q) {
        if (questionMatch && questionMatch.variable === q.variable) {
          if (q.showSubquestionIf && q.subquestions) {
            var answerMatchesSubQuestionIf = false;

            if (answer.answer && answer.answer.toString) {
              answerMatchesSubQuestionIf = answer.answer.toString() === q.showSubquestionIf;
            }

            if (answerMatchesSubQuestionIf) {
              _this3.buildSubquestions(q.subquestions);
            } else {
              if (_this3.subquestionAnswers && _this3.subquestionAnswers.length > 0) {
                _this3.removeSubquestionsAndSend(_this3.subquestionAnswers);
              }
            }
          }
        }

        nueQuestions.pushObject(q);
      });
      return nueQuestions;
    }),
    buildSubquestions: function buildSubquestions(subQuestions) {
      var _this4 = this;

      var subquestionAnswers = [];
      subQuestions.forEach(function (sq) {
        var subQuestionAnswerValue = sq.default;
        var nueOverride = {
          scope: Ember.get(_this4, 'answer.scope'),
          question: sq,
          answer: subQuestionAnswerValue,
          isSubQuestion: true
        };

        if (Ember.get(_this4, 'answerOverrides')) {
          var match = Ember.get(_this4, 'answerOverrides').filterBy('scope', Ember.get(_this4, 'answer.scope')).findBy('question', Ember.get(sq, 'variable'));

          if (match) {
            Ember.set(nueOverride, 'answer', Ember.get(match, 'answer'));
          }
        }

        subquestionAnswers.pushObject(nueOverride);
      });
      Ember.run.next(function () {
        Ember.set(_this4, 'subquestionAnswers', subquestionAnswers);
        Ember.get(_this4, 'addSubrowQuestions')(subquestionAnswers);
      });
    },
    removeSubquestionsAndSend: function removeSubquestionsAndSend(answers) {
      var _this5 = this;

      var removed = [].concat(answers);
      Ember.set(this, 'subquestionAnswers', null);
      Ember.run.next(function () {
        Ember.get(_this5, 'removeSubrowQuestions')(removed);
      });
    }
  });

  _exports.default = _default;
});