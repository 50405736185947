define("shared/components/principal-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+0jd/mdJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[11,\"oninput\",[28,\"perform\",[[24,[\"search\"]]],[[\"value\"],[\"target.value\"]]]],[11,\"placeholder\",[28,\"t\",[[28,\"if\",[[28,\"eq\",[[24,[\"access\",\"provider\"]],\"githubconfig\"],null],\"inputIdentity.placeholder.generic\",\"inputIdentity.placeholder.github\"],null]],null]],[10,\"class\",\"form-control\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/principal-search/template.hbs"
    }
  });

  _exports.default = _default;
});