define("shared/components/schema/input-workload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z7Rtx1+2",
    "block": "{\"symbols\":[\"list\",\"group\",\"svc\"],\"statements\":[[4,\"if\",[[24,[\"editing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"select\",true],[11,\"class\",[29,[[22,\"selectClass\"]]]],[11,\"onchange\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"selected\"]]],null]],[[\"value\"],[\"target.value\"]]]],[11,\"disabled\",[22,\"readOnly\"]],[8],[0,\"\\n    \"],[7,\"option\",true],[11,\"selected\",[28,\"eq\",[[24,[\"selected\"]],null],null]],[8],[1,[28,\"t\",[\"schema.inputService.prompt\"],null],false],[9],[0,\"\\n\"],[4,\"each\",[[28,\"-each-in\",[[24,[\"grouped\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"optgroup\",true],[11,\"label\",[23,2,[]]],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"          \"],[7,\"option\",true],[11,\"selected\",[28,\"eq\",[[24,[\"selected\"]],[23,3,[\"id\"]]],null]],[11,\"value\",[23,3,[\"id\"]]],[8],[1,[23,3,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"workload\",[24,[\"selected\"]]]],{\"statements\":[[1,[22,\"readableWorkload\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/schema/input-workload/template.hbs"
    }
  });

  _exports.default = _default;
});