define("shared/components/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DljC+BKI",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"tooltip-element\",null,[[\"type\",\"model\",\"tooltipTemplate\",\"aria-describedby\",\"inlineBlock\"],[\"tooltip-basic\",[24,[\"tooltipContent\"]],\"tooltip-static\",\"tooltip-base\",false]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"progress\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"progress-bar \",[22,\"colorClass\"]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});