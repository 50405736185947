define("shared/components/textarea-autogrow/component", ["exports", "ui/utils/platform", "shared/mixins/intl-placeholder", "jquery"], function (_exports, _platform, _intlPlaceholder, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextArea.extend(_intlPlaceholder.default, {
    intl: Ember.inject.service(),
    minHeight: 0,
    curHeight: null,
    maxHeight: 200,
    tagName: 'textarea',
    classNames: ['no-resize', 'no-ease'],
    attributeBindings: ['spellcheck'],
    classNameBindings: ['bg'],
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, 'initHeights');
    },
    changed: Ember.observer('value', function () {
      Ember.run.debounce(this, 'autoSize', 100);
    }),
    bg: Ember.computed('disabled', function () {
      if (Ember.get(this, 'disabled')) {
        return 'bg-disabled';
      }
    }),
    isSmall: Ember.computed(function () {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      return (0, _jquery.default)().hasClass('input-sm');
    }),
    initHeights: function initHeights() {
      var _this = this;

      if (this.get('minHeight') === 0) {
        this.set('minHeight', this.get('isSmall') ? 31 : 36);
      }

      this.autoSize();
      (0, _jquery.default)().on('paste', function () {
        Ember.run.later(_this, 'autoSize', 100);
      });
    },
    autoSize: function autoSize() {
      var _this2 = this;

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var el = this.element;
      var $el = (0, _jquery.default)(el); // eslint-disable-line

      Ember.run(function () {
        $el.css('height', '1px');
        var border = parseInt($el.css('borderTopWidth'), 10) || 0 + parseInt($el.css('borderBottomWidth'), 10) || 0;
        var magic = (_this2.get('isSmall') ? -2 : 0) + (_platform.isGecko ? 1 : 2); // Sigh, but it's wrong without magic fudge

        var neu = Math.max(_this2.get('minHeight'), Math.min(el.scrollHeight + border + magic, _this2.get('maxHeight')));
        $el.css('overflowY', el.scrollHeight > neu ? 'auto' : 'hidden');
        $el.css('height', "".concat(neu, "px"));

        _this2.set('curHeight', neu);
      });
    }
  });

  _exports.default = _default;
});