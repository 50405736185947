define("shared/components/schema/input-container/component", ["exports", "shared/components/schema/input-container/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    allContainers: Ember.inject.service(),
    layout: _template.default,
    selected: null,
    // Selected service ID
    exclude: null,
    // ID or array of IDs to exclude from list
    stack: null,
    // The current stack, to generate stack-relative names
    // For use as a catalog question
    field: null,
    // Read default from a schema resourceField
    value: null,
    // stackName/serviceName string output
    init: function init() {
      this._super.apply(this, arguments);

      var def = this.get('field.default');

      if (def && !this.get('selected')) {
        var match = this.get('allContainers.list').findBy('name', def);
        this.set('selected', match || null);
      }
    },
    selectedChanged: Ember.observer('selected', function () {
      var id = this.get('selected');
      var str = null;

      if (id) {
        var container = this.get('allContainers').byId(id);

        if (container) {
          if (this.get('stack') && this.get('stack') === container.get('stack')) {
            str = container.get('name');
          } else {
            str = "".concat(container.get('stack.name'), "/").concat(container.get('name'));
          }
        }
      }

      this.set('value', str);
    }),
    grouped: Ember.computed('allContainers.list.[]', 'canBalanceTo', 'canHaveContainers', function () {
      var list = this.get('allContainers.list');
      var exclude = this.get('exclude');

      if (exclude) {
        if (!Ember.isArray(exclude)) {
          exclude = [exclude];
        }

        list = list.filter(function (x) {
          return !exclude.includes(x.id);
        });
      }

      return this.get('allContainers').group(list);
    })
  });

  _exports.default = _default;
});