define("global-admin/global-registry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NVatyVvp",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"registry-catalog\",null,[[\"app\",\"cluster\",\"project\",\"nsExists\",\"storageClasses\",\"persistentVolumeClaims\",\"showForm\",\"globalRegistryEnabled\",\"cancel\"],[[24,[\"model\",\"app\"]],[24,[\"model\",\"cluster\"]],[24,[\"model\",\"project\"]],[24,[\"model\",\"nsExists\"]],[24,[\"model\",\"storageClasses\"]],[24,[\"model\",\"persistentVolumeClaims\"]],[24,[\"model\",\"showForm\"]],[24,[\"model\",\"globalRegistryEnabled\"]],[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/global-registry/template.hbs"
    }
  });

  _exports.default = _default;
});