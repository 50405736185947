define("shared/components/metrics-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D06dF4+X",
    "block": "{\"symbols\":[\"parent\",\"&default\"],\"statements\":[[4,\"accordion-list-item\",null,[[\"grafanaUrl\",\"title\",\"detail\",\"expandAll\",\"expand\",\"expanded\",\"componentName\"],[[24,[\"grafanaUrl\"]],[24,[\"title\"]],[28,\"t\",[\"metricsAction.description\"],null],[24,[\"expandAll\"]],[28,\"action\",[[23,0,[]],[24,[\"expandFn\"]]],null],[24,[\"expanded\"]],\"sortable-table\"]],{\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"expanded\"]],[24,[\"expandAll\"]]],null]],null,{\"statements\":[[0,\"    \"],[14,2,[[23,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/metrics-summary/template.hbs"
    }
  });

  _exports.default = _default;
});