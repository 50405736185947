define("shared/components/save-cancel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ctH83VWx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"saving\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",[29,[\"btn btn-disabled \",[22,\"savingColor\"]]]],[3,\"action\",[[23,0,[]],\"doNothing\"]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"icon icon-spinner icon-spin\"],[8],[9],[0,\"\\n    \"],[1,[28,\"t\",[[24,[\"savingLabel\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"disabled\",[22,\"saveDisabled\"]],[12,\"class\",[29,[\"btn \",[28,\"if\",[[24,[\"saved\"]],[24,[\"savedColor\"]],[24,[\"saveColor\"]]],null]]]],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],\"save\"]],[8],[0,\"\\n    \"],[1,[28,\"t\",[[24,[\"btnLabel\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[14,1],[0,\"\\n\"],[4,\"unless\",[[24,[\"cancelDisabled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",[29,[\"btn \",[22,\"cancelColor\"]]]],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"\\n    \"],[1,[28,\"t\",[[24,[\"cancelLabel\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/save-cancel/template.hbs"
    }
  });

  _exports.default = _default;
});