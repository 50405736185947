define("shared/components/cluster-template-override-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UR+c/dEr",
    "block": "{\"symbols\":[],\"statements\":[[5,\"tooltip-element\",[],[[\"@type\",\"@tooltipTemplate\",\"@model\",\"@inlineBlock\"],[\"tooltip-toggle-override\",\"tooltip-static\",[22,\"tooltipModel\"],true]],{\"statements\":[[0,\"\\n  \"],[5,\"btn-toggle\",[],[[\"@toggled\",\"@checked\"],[[28,\"action\",[[23,0,[]],\"addOverride\"],null],[28,\"mut\",[[24,[\"btnToggled\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/cluster-template-override-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});