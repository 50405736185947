define("nodes/components/driver-azure/component", ["exports", "shared/mixins/node-driver", "nodes/components/driver-azure/template", "ui/utils/azure-choices", "shared/utils/util"], function (_exports, _nodeDriver, _template, _azureChoices, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DRIVER = 'azure';
  var CONFIG = 'azureConfig';
  var IPCHOICES = [{
    'name': 'Static',
    'value': 'staticPublicIp=true,noPublicIp=false'
  }, {
    'name': 'Dynamic',
    'value': 'staticPublicIp=false,noPublicIp=false'
  }, {
    'name': 'None',
    'value': 'staticPublicIp=true,noPublicIp=true'
  }];
  var MANAGED = 'managed';
  var UNMANAGED = 'unmanaged';
  var DISK_CHOICES = [{
    label: 'nodeDriver.azure.managedDisks.unmanaged',
    value: UNMANAGED
  }, {
    label: 'nodeDriver.azure.managedDisks.managed',
    value: MANAGED
  }];

  var _default = Ember.Component.extend(_nodeDriver.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    environments: _azureChoices.environments,
    driverName: DRIVER,
    publicIpChoices: IPCHOICES,
    diskChoices: DISK_CHOICES,
    sizeChoices: _azureChoices.sizes,
    managedDisks: UNMANAGED,
    model: null,
    openPorts: null,
    publicIpChoice: null,
    config: Ember.computed.alias("model.".concat(CONFIG)),
    storageTypeChoices: _azureChoices.storageTypes.sortBy('name'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', function () {
        Ember.setProperties(_this, {
          publicIpChoice: _this.initPublicIpChoices(Ember.get(_this, 'config.staticPublicIp'), Ember.get(_this, 'config.noPublicIp')),
          openPorts: _this.initOpenPorts(Ember.get(_this, 'config.openPort')),
          managedDisks: Ember.get(_this, 'config.managedDisks') ? MANAGED : UNMANAGED
        });

        if (!_this.editing) {
          Ember.set(_this, 'config.nsg', "rancher-managed-".concat((0, _util.randomStr)(8, 8)));
        }
      });
    },
    actions: {
      finishAndSelectCloudCredential: function finishAndSelectCloudCredential(credential) {
        Ember.set(this, 'model.cloudCredentialId', Ember.get(credential, 'id'));
      }
    },
    diskTypeChanged: Ember.observer('managedDisks', function () {
      Ember.set(this, 'config.managedDisks', Ember.get(this, 'managedDisks') === MANAGED);
    }),
    evironmentChoiceObserver: Ember.observer('config.environment', function () {
      var environment = Ember.get(this, 'config.environment');
      Ember.set(this, 'config.location', _azureChoices.regions[environment][0].name);
    }),
    ipChoiceObserver: Ember.observer('publicIpChoice', function () {
      var publicIpChoice = Ember.get(this, 'publicIpChoice');

      if (Ember.get(this, 'publicIpChoices').findBy('value', publicIpChoice).name === 'None') {
        Ember.set(this, 'config.usePrivateIp', true);
      }
    }),
    publicIpObserver: Ember.observer('publicIpChoice', function () {
      var _this2 = this;

      var elChoice = Ember.get(this, 'publicIpChoice');
      var choice = Ember.get(this, 'publicIpChoices').findBy('value', elChoice);
      choice = choice.value.split(',');
      choice.forEach(function (val) {
        var tmp = val.split('=');
        Ember.set(_this2, "config.".concat(tmp[0]), tmp[1] === 'true' ? true : false);
      });
    }),
    openPort: Ember.observer('openPorts', function () {
      var str = (Ember.get(this, 'openPorts') || '').trim();
      var ary = [];

      if (str.length) {
        ary = str.split(/\s*,\s*/);
      }

      Ember.set(this, 'config.openPort', ary);
    }),
    regionChoices: Ember.computed('config.environment', function () {
      var environment = Ember.get(this, 'config.environment');
      return _azureChoices.regions[environment];
    }),
    privateSet: Ember.computed('publicIpChoice', function () {
      var publicIpChoice = Ember.get(this, 'publicIpChoice');

      if (publicIpChoice && Ember.get(this, 'publicIpChoices').findBy('value', publicIpChoice).name === 'None') {
        return true;
      }

      return false;
    }),
    setUsePrivateIp: Ember.computed('publicIpChoice', function () {
      var publicIpChoice = Ember.get(this, 'publicIpChoice');

      if (publicIpChoice && Ember.get(this, 'publicIpChoices').findBy('value', publicIpChoice).name === 'None') {
        return Ember.set(this, 'config.usePrivateIp', true);
      }

      return false;
    }),
    bootstrap: function bootstrap() {
      var config = Ember.get(this, 'globalStore').createRecord({
        type: CONFIG,
        subscriptionId: '',
        openPort: ['6443/tcp', '2379/tcp', '2380/tcp', '8472/udp', '4789/udp', '9796/tcp', '10256/tcp', '10250/tcp', '10251/tcp', '10252/tcp']
      });
      Ember.set(this, "model.".concat(CONFIG), config);
    },
    initOpenPorts: function initOpenPorts(ports) {
      return ports ? ports.join(',') : '';
    },
    initPublicIpChoices: function initPublicIpChoices(staticPublicIp, noPublicIp) {
      if (staticPublicIp && noPublicIp) {
        return Ember.get(this, 'publicIpChoices').findBy('name', 'None').value;
      } else if (staticPublicIp && !noPublicIp) {
        return Ember.get(this, 'publicIpChoices').findBy('name', 'Static').value;
      } else {
        return Ember.get(this, 'publicIpChoices').findBy('name', 'Dynamic').value;
      }
    },
    validate: function validate() {
      this._super();

      var errors = Ember.get(this, 'errors') || [];

      if (!Ember.get(this, 'model.name')) {
        errors.push(this.intl.t('nodeDriver.nameError'));
      }

      if (!this.validateCloudCredentials()) {
        errors.push(this.intl.t('nodeDriver.cloudCredentialError'));
      }

      if (errors.length) {
        Ember.set(this, 'errors', errors.uniq());
        return false;
      }

      return true;
    }
  });

  _exports.default = _default;
});