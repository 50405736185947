define("shared/components/storage-class/provisioner-azure-disk/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CbQf1f3P",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row mb-20\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col span-4\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[[28,\"concat\",[\"cruStorageClass.\",[24,[\"provisioner\"]],\".storageaccounttype.label\"],null]],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"input-or-display\",null,[[\"editable\",\"value\"],[[24,[\"editing\"]],[24,[\"storageaccounttype\"]]]],{\"statements\":[[0,\"      \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"classNames\",\"placeholder\"],[\"text\",[24,[\"storageaccounttype\"]],\"form-control\",[28,\"t\",[[28,\"concat\",[\"cruStorageClass.\",[24,[\"provisioner\"]],\".storageaccounttype.placeholder\"],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col span-4\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[[28,\"concat\",[\"cruStorageClass.\",[24,[\"provisioner\"]],\".kind.label\"],null]],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"editing\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"new-select\",null,[[\"classNames\",\"content\",\"value\",\"optionLabelPath\",\"localizedLabel\"],[\"form-control\",[24,[\"kindOptions\"]],[24,[\"kind\"]],\"translationKey\",true]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"text-muted\"],[8],[0,\"\\n        \"],[1,[22,\"kind\"],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/storage-class/provisioner-azure-disk/template.hbs"
    }
  });

  _exports.default = _default;
});