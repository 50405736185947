define("global-admin/components/form-global-roles/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gvw6FILo",
    "block": "{\"symbols\":[\"role\",\"role\",\"baseRole\"],\"statements\":[[5,\"accordion-list-item\",[],[[\"@title\",\"@detail\",\"@expandOnInit\",\"@showExpand\"],[[28,\"t\",[\"formGlobalRoles.title\"],null],[28,\"t\",[\"formGlobalRoles.description\"],[[\"appName\",\"type\"],[[24,[\"settings\",\"appName\"]],[28,\"if\",[[24,[\"user\"]],\"user\",\"group\"],null]]]],true,false]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"baseRoles\"]]],null,{\"statements\":[[0,\"    \"],[5,\"form-global-roles-row\",[],[[\"@model\"],[[23,3,[]]]]],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"accordion-list-item\",[],[[\"@title\",\"@detail\",\"@expandOnInit\",\"@showExpand\"],[[28,\"t\",[\"formGlobalRoles.mode.userCreated.label\"],null],[28,\"t\",[\"formGlobalRoles.mode.userCreated.detail\"],[[\"type\"],[[28,\"if\",[[24,[\"user\"]],\"user\",\"group\"],null]]]],true,false]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"userRoles\"]]],null,{\"statements\":[[0,\"    \"],[5,\"form-global-roles-row\",[],[[\"@model\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"accordion-list-item\",[],[[\"@title\",\"@detail\",\"@expandOnInit\",\"@showExpand\"],[[28,\"t\",[\"formGlobalRoles.mode.custom.label\"],null],[28,\"t\",[\"formGlobalRoles.mode.custom.detail\"],[[\"type\"],[[28,\"if\",[[24,[\"user\"]],\"user\",\"group\"],null]]]],true,false]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"additionalRoles\"]]],null,{\"statements\":[[0,\"    \"],[5,\"form-global-roles-row\",[],[[\"@model\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/components/form-global-roles/template.hbs"
    }
  });

  _exports.default = _default;
});