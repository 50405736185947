define("global-admin/cluster-templates/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DisAsvej",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header clearfix\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"pull-left\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"clusterTemplatesPage.new.header\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"cru-cluster-template\",[],[[\"@clusterTemplate\",\"@clusterTemplateRevision\",\"@users\",\"@psps\",\"@mode\",\"@forceExpandOnInit\",\"@cancel\",\"@done\"],[[24,[\"model\",\"clusterTemplate\"]],[24,[\"model\",\"clusterTemplateRevision\"]],[24,[\"model\",\"users\"]],[24,[\"model\",\"psps\"]],\"new\",false,[28,\"action\",[[23,0,[]],\"cancel\"],null],[28,\"action\",[[23,0,[]],\"done\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/cluster-templates/new/template.hbs"
    }
  });

  _exports.default = _default;
});