define("shared/components/schema/input-secret/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tzW+cXfx",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"new-select\",null,[[\"classNames\",\"content\",\"prompt\",\"localizedPrompt\",\"value\"],[\"form-control\",[24,[\"filtered\"]],[28,\"concat\",[\"schema.inputSecret.\",[24,[\"type\"]]],null],true,[24,[\"selected\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/schema/input-secret/template.hbs"
    }
  });

  _exports.default = _default;
});