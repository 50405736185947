define("shared/components/storage-class/provisioner-longhorn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tlH/Hoen",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row mb-20\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col span-6\"],[8],[0,\"\\n    \"],[5,\"form-key-value\",[],[[\"@addActionLabel\",\"@changed\",\"@editing\",\"@initialMap\"],[\"clusterNew.googlegke.nodeLabels.addAction\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\"]]],null]],null],[28,\"not\",[[28,\"eq\",[[24,[\"mode\"]],\"edit\"],null]],null],[22,\"model\"]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/storage-class/provisioner-longhorn/template.hbs"
    }
  });

  _exports.default = _default;
});