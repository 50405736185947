define("global-admin/components/form-google-auth-read/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fp+m4ydv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col span-4\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"inline-form\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"acc-label pb-5\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"authPage.googleoauth.notAuthenticated.form.adminEmail.labelText\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[24,[\"authConfig\",\"adminEmail\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col span-4\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"inline-form\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"acc-label pb-5\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"authPage.googleoauth.notAuthenticated.form.hostname.labelText\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[24,[\"authConfig\",\"hostname\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col span-4\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"inline-form\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"acc-label pb-5\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"authPage.googleoauth.notAuthenticated.form.nestedGroupMembershipEnabled.labelText\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"authConfig\",\"nestedGroupMembershipEnabled\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"t\",[\"generic.enabled\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[28,\"t\",[\"generic.disabled\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/components/form-google-auth-read/template.hbs"
    }
  });

  _exports.default = _default;
});